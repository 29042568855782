/**************************************************
 * Nombre:       NoticiasAnteriores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../../Constantes";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Noticias from "../../Tarjetas/Tarjeta_Noticias";
import {theme} from "../../../Tema";


const NoticiasAnteriores = ({data}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontSize: sCell ? 30 : 40,
                            fontWeight: 700,
                            textAlign: sCell ? 'center' : 'left'
                        }}
                    >
                        Noticias Anteriores
                    </Typography>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                        <Ho_ManejoTargetas Card={Tarjeta_Noticias} data={data} spacing={8}/>

                    </Grid>


                </Grid>

            </Grid>

        </Grid>
    )
}

export default NoticiasAnteriores;


const noticias = [
    {
        fecha: '27 de Septiembre',
        titulo: 'Quichinche celebró sus fiestas patrimoniales',
        contenido: 'Del 6 al 19 de marzo la parroquia San José de Quichinche del cantón Otavalo, celebró sus fiestas en honor a su santo patrono. El programa de fiestas contempló varias actividades de tipo religioso, cultural y deportivo como: novenas, pregón de fiestas, Cross Contry a caballo, partidos de fútbol, ecuavoley, bandas del pueblo, peregrinaciones religiosas, festivales pirotécnicos, quema de castillos, carrera de coches de madera, shows artístico-bailables y festivales folklóricos en las que participarán todos los parroquianos.',
    },
    {
        fecha: '02 de Octubre',
        titulo: 'Festejo de los 137 años de paroquialización',
        contenido: 'La celebración de San Jose de Quichinche por su parroquialización de llevara acabo del 19 al 23 de ocutubre, se realizaran actividades gastronomicas, deportivas, musicales y culturales. El GAD parroquial de quichinche hace una calurosa invitacion a ser participes tanto locales como visitantes a estas actividades. La Tradicionalidad y el folclor local de San Jose de Quichinche seran los protagonistas en estos festejos provinciales, manteniendo, fortaleciendo y mostrando sus tradiciones, festejo y cultura autoctona.',
    }
]