/**************************************************
 * Nombre:       Dialogo_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Noticia from "../Formularios/Formulario_Noticia";
import {LoadingButton} from "@mui/lab";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";


const Dialogo_Noticia = ({id, cerrar}) => {
    const {Formulario, props, entidad, setEntidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Noticia,
        nombre: 'noticia'
    })
    const [cargando, setCargando] = useState(false)


    const guardar = () => {

        if (!verificarCamposObligatorios(entidad, ['titulo', 'fecha', 'parrafo'])) {
            return
        }

        setCargando(true)
        guardarDoc('noticias', entidad).then((dox) => {
            if (dox.res) {
                alert('Cambios realizados con exito')
                limpiarEntidad()
                setCargando(false)
                cerrar()
            } else {
                setCargando(false)
                alert('Algo inesperado paso, por favor intentalo nuevamente\nErr: NOT001')
            }
        })

    }

    const borrar = () => {
        borrarDoc('noticias', entidad.id).then((dox) => {
            if (dox.res) {
                alert('Elemento borrado')
            }
        })
    }


    useEffect(() => {
        if (id) {
            setEntidad(id)
        }
    }, [id])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton
                    onClick={() => guardar()}
                    variant={'contained'}
                    color={'primary'}
                    loading={cargando}
                    sx={{px: 4, fontSize: 16, color: '#fff'}}
                >
                    Guardar Cambios
                </LoadingButton>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                {entidad && entidad.id &&
                <ButtonBase
                    onClick={() => borrar()}
                >
                    <Typography sx={{color: '#00000080'}}>Borrar Noticia</Typography>
                </ButtonBase>
                }
            </Grid>

        </Grid>
    )
}

export default Dialogo_Noticia;