/**************************************************
 * Nombre:       Formulario_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Buildings, Call, Messages, Shop, User, Whatsapp} from "iconsax-react";


const Formulario_Registro = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={User} label={"Nombre"} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={Call} label={"Telefono"} dato={'telefono'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={Shop} label={"Nombre del Negocio"} dato={'negocio'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={Whatsapp} label={"WhatsApp del Negocio"} dato={'wass'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Buildings} label={"Direccion del Negocio"} dato={'direccion'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Messages} label={"Cometario adicional"} dato={'comentario'} lineas={6} />
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Registro;