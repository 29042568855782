/**************************************************
 * Nombre:       Dialogo_Lugar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import DetalleItem from "../Secciones/Componentes/DetalleItem";


const Dialogo_Lugar = ({id}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DetalleItem item={id}/>


        </Grid>
    )
}

export default Dialogo_Lugar;