/**************************************************
 * Nombre:       Tarjeta_Testimonio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../Constantes";
import {theme} from "../../Tema";


const Tarjeta_Testimonio = ({valores}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Typography
                    sx={{fontFamily: 'Playfair Display SC', color: PRIMARIO, fontSize: sCell ? 20:  24, fontWeight: 700}}
                >
                    {valores.nombre}
                </Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 4,
                    textOverflow: "ellipsis",
                    textAlign: 'justify'
                }} >
                    {valores.comentario}
                </Typography>
            </Grid>


        </Grid>
    )
}

export default Tarjeta_Testimonio;