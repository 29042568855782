/**************************************************
 * Nombre:       RegistraNegocio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Registro from "../../Formularios/Formulario_Registro";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const RegistraNegocio = () => {
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({Form: Formulario_Registro, nombre: 'registro'})
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        setCargando(true)
        entidad.fecha = new Date().getTime()
        guardarDoc('potenciales', entidad).then((dox) => {
            if (dox.res) {
                alert("Formulario enviado con exito\nLa persona encargada se prondra en contacto muy pronto")
                limpiarEntidad()
            } else {
                alert("Algo inesperado paso\nPor favor intentalo mas tarde\nERR:POT001")
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontWeight: 800,
                            fontSize: 32
                        }}>
                        Registra tu negocio
                    </Typography>
                </Grid>

                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Formulario {...props} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                    <LoadingButton
                        loading={cargando}
                        onClick={() => guardar()}
                        sx={{px: 4, borderRadius: 6}} variant={'outlined'}>Enviar</LoadingButton>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default RegistraNegocio;
