/**************************************************
 * Nombre:       Formulario_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {FormBase, ImagenDimencion} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Message, Message2, Text} from "iconsax-react";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const Formulario_Noticia = ({...props}) => {
    const [cargando, setCargando] = useState(false)

    const ponerHome = () => {

        if (!verificarCamposObligatorios(props.entidad, ['titulo', 'fecha', 'parrafo'])) {
            return
        }


        setCargando(true)
        let obj = props.entidad
        obj.idNoticia = props.entidad.id
        obj.id = 'noticia'

        guardarDoc('noticiaHome', obj).then((dox) => {
            if (dox.res) {
                alert('Noticia colocada en Home')
                setCargando(false)
            } else {
                setCargando(false)
                alert('Algo inesperado paso, por favor intentalo nuevamente\nErr: NOT002')
            }
        })
    }

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={3} sm={12} xs={12}>

                </Grid>

                <Grid item lg={6} sm={12} xs={12} sx={{marginBottom: 4}}>
                    <FormBase.ImagenDimencion dato={'img'} funcion={subirUnaImagenCroper} x={3} y={2}
                                              carpeta={'noticias'}/>
                </Grid>

                <Grid item lg={3} sm={12} xs={12}>

                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIconFecha dato={'fecha'} label={"Fecha de Publicacion"}/>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                    {props.entidad && props.entidad.id &&
                    <LoadingButton
                        loading={cargando}
                        onClick={() => ponerHome()}
                        variant={"contained"}
                    >Colocar en Home</LoadingButton>
                    }
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Text} dato={'titulo'} label={"Titulo Noticia"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} dato={'parrafo'} label={"Primer Parrafo"} lineas={10}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message2} dato={'contenido'} label={"Contenido de Noticia"} lineas={10}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Noticia;