/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, Tab, Tabs, useMediaQuery} from "@mui/material";
import {CATEGORIAS} from "../../../Constantes";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Items from "../../Tarjetas/Tarjeta_Items";
import useRecuperarColleccionListeningProg from "../../../Servicios/BD/useRecuperarColleccionListeningProg";
import {where} from "firebase/firestore";
import {theme} from "../../../Tema";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Lugar from "../../Dialogos/Dialogo_Lugar";


const ListaItems = ({tipo = 'nones'}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [wheres, setWheres] = useState([where('categoria', '==', tipo)])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'lugares', wheres: wheres, orden: 'nombre'})
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Lugar})


    useEffect(() => {
        setWheres([where('categoria', '==', tipo)])
    }, [tipo])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 10}}>
                    <Ho_ManejoTargetas data={data} Card={Tarjeta_Items} click={abrir} spacing={8} lg={6} sm={6}/>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default ListaItems;

