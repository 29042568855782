/**************************************************
 * Nombre:       Noticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import ApartadoGrande from "./Componentes/ApartadoGrande";
import Comentarios from "./Componentes/Comentarios";
import NoticiasAnteriores from "./Componentes/NoticiasAnteriores";
import ContactosNoticias from "./Componentes/ContactosNoticias";
import {useParams} from 'react-router-dom'
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {irArriba} from "../../Utilidades/irArriba";


const Noticias = () => {
    const parms = useParams()
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'noticias', wheres: wheres, direccion: 'desc'})
    const [noticia, setNoticia] = useState({})


    useEffect(() => {
        if (parms && parms.id) {

            setNoticia({})

            let id = parms.id
            if (parms.idNoticia) {
                id = parms.idNoticia
            }

            obtenerDoc('noticias', id).then((dox) => {
                if (dox.res) {
                    setNoticia(dox.data)
                }
            })
        } else {

            obtenerDoc('noticiaHome', 'noticia').then((dox) => {
                if (dox.res) {
                    setNoticia(dox.data)
                }
            })

        }

        irArriba()
    }, [parms])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12}>
                <ApartadoGrande noticia={noticia}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                <Comentarios item={noticia}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                <NoticiasAnteriores data={data}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                <Button
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{borderRadius: 6, px: 6}}>Carga Mas</Button>
            </Grid>


        </Grid>
    )
}

export default Noticias;
