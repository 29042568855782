/**************************************************
 * Nombre:       Comisiones
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Pqrs from "../Tarjetas/Tarjeta_Pqrs";
import {ACENTO} from "../../Constantes";
import {React, useState} from "react";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {Flag} from "iconsax-react";
import Dialogo_Comisiones from "../Dialogos/Dialogo_Comisiones";
import Tarjeta_Comision from "../Tarjetas/Tarjeta_Comision";

const Comisiones = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Comisiones})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'comisiones',
        wheres: wheres,
        orden: 'nombre'
    })

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={6} sm={3} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>Comisiones</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={9} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Button
                            onClick={() => abrir()}
                            startIcon={<Flag variant={'Bold'}/>} variant={'contained'}>Nueva Comision</Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Comision} data={data} click={abrir} pxCell={0}/>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button
                    color={'secondary'}
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{color: ACENTO}}>Cargar Mas</Button>
            </Grid>


        </Grid>
    )

}
export default Comisiones