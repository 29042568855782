/**************************************************
 * Nombre:       Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../Constantes";
import YouTube from "react-youtube";
import {theme} from '../../Tema'
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Persona from "../Tarjetas/Tarjeta_Persona";
import foto from '../../Recursos/fotojunta.png'
import Pqrs from "./Componentes/Pqrs";
import veronica from '../../Recursos/veronica.png'
import monica from '../../Recursos/monica.png'
import maribel from '../../Recursos/maribel.png'
import eduardo from '../../Recursos/hombre1.png'
import israel from '../../Recursos/hombre2.png'
import {irArriba} from "../../Utilidades/irArriba";

const Contactos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    const opts = {
        height: masSM ? '600' : '190',
        width: masSM ? '1080' : '340',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            modestbranding: 0
        },
    };

    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 4 : 10}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            fontSize: masSM ? 120 : 50,
                            fontWeight: 800,
                            color: '#00601520',
                            lineHeight: 1.1
                        }}>SAN
                        JOSE
                        DE <br/>
                        QUICHINCHE</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? -12 : -24}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            fontSize: masSM ? 36 : 28,
                            fontWeight: 700,
                            color: PRIMARIO,
                            textAlign: 'center'
                        }}
                    >
                        GAD Parriquial de Quichinche
                    </Typography>
                </Grid>

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : -14}}>
                    <Typography
                        sx={{
                            fontSize: sCell ? 18 : 20,
                            textAlign: 'justify'
                        }}
                    >Como junta parroquial de Quichince estamos comprometidos en poder brindar un mejor servicio para
                        todas las comunidades y los barrio que conforman nuestra parroquia juntos construiremos una
                        parroquia digna con mayores oportunidades para todas y todos
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 10}}>
                    <YouTube
                        videoId={'ZzN6lAgKvaw'} // defaults -> null
                        id={'ZzN6lAgKvaw'} // defaults -> null
                        title={"titulo"}
                        opts={opts}

                    />
                </Grid>

                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 10 : 18}}>
                    <Ho_ManejoTargetas Card={Tarjeta_Persona} data={personas} lg={6} spacing={8} sm={6}/>
                </Grid>

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 16}}>
                    <Typography
                        sx={{
                            fontSize: sCell ? 18 : 20,
                            textAlign: 'justify'
                        }}
                    >
                        La junta parroquial de San Jose de Quichinche esta para mejorar las condiciones, oporunidades y
                        dignidad de cada familia y persona de la parroquia
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 14}}>
                    <img src={foto} width={sCell ? '90%' : '80%'} height={'auto'}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 0 : -6}}>
                    <Pqrs/>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Contactos;

const personas = [
    {
        nombre: 'Msg. Veronica Estrada',
        cargo: 'Presidenta del GAD Paroquial',
        img: veronica
    }, {
        nombre: 'Dra. Monica Vaca' +
            '',
        cargo: 'Vocal del GAD Paroquial',
        img: monica
    }, {
        nombre: 'Sr. Maribel Torres',
        cargo: 'Vocal del GAD Paroquial',
        img: maribel
    }, {
        nombre: 'Sr. Edgar Madera',
        cargo: 'Vocal del GAD Paroquial',
        img: eduardo
    }, {
        nombre: 'Sr. Israel Tuquerres',
        cargo: 'Vocal del GAD Paroquial',
        img: israel
    },
]