/**************************************************
 * Nombre:       Formulario_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {DividerTexto, FormBase, ImagenDimencion} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Buildings, Calendar, Calendar1, Call, Message, Message2, Shop, Text, User, Whatsapp} from "iconsax-react";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";
import {ITEMSLOTAIP, MESES, YEARS} from "../../Constantes";
import {subirArchivoPDF} from "../../Servicios/Storage/subirArchivoPDF";


const Formulario_Lotaip = ({...props}) => {
    const [cargando, setCargando] = useState(false)


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Calendar1} dato={'year'} opciones={YEARS} label={'Año'}/>
                </Grid>


                {ITEMSLOTAIP.map((it, index) => {
                    return (
                        <Grid item lg={12} sm={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >


                                <Grid item lg={12} sm={12} xs={12}>
                                    <FormBase.DividerTexto texto={'Apartado ' + it.apartado} xs={2} sm={4} lg={4}/>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2, px: 2}}>
                                    <FormBase.InputArchivoPDF dato={it.apartado} funcion={subirArchivoPDF}
                                                              label={"PDF Apartado " + it.apartado} carpeta={'Pdfs'}/>
                                </Grid>

                                {it.items.map((its, index) => {
                                    return (
                                        <Grid item lg={12} sm={12} xs={12} sx={{pl: 6, pr: 2, mt: 1}}>
                                            <FormBase.InputArchivoPDF funcion={subirArchivoPDF} dato={its.letra}
                                                                      label={"PDF Letra " + its.letra}
                                                                      carpeta={'Pdfs'}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>

                        </Grid>
                    )
                })}


            </Grid>
        </FormBase>
    )
}

export default Formulario_Lotaip;