import {useLocation} from 'react-router-dom'
import {useEffect, useState} from "react";

export const useObtenerLocation = () => {
    const location = useLocation()
    const [nombre, setNombre] = useState('')


    useEffect(() => {
        if (location.pathname !== '/') {
            setNombre(location.pathname.split('/')[1])
        } else {
            setNombre('')
        }

    }, [location])
    return {
        nombre,
        location
    }

}