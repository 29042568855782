/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography, styled, useMediaQuery, ButtonBase, Button} from "@mui/material";
import logo from "../../../Recursos/logofondo.png";
import fondo1 from '../../../Recursos/fotouno.jpg'
import fondo2 from '../../../Recursos/fotodos.jpg'
import fondo3 from '../../../Recursos/fototres.jpg'
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {theme} from "../../../Tema";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";
import YouTube from "react-youtube";
import {ACENTO, LINKPDOT, PRIMARIO, REDES} from "../../../Constantes";
import {irUrl} from "../../../Utilidades/irUrl";


const Portada = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [portadas, setPortadas] = useState(portadaDefault)

    const opts = {
        height: sCell ? '238' : '380',
        width: sCell ? '420' : '610',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            control: 0

        },
    };

    const Fondo = ({portadas}) => {

        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{
                    minHeight: sCell ? 350 : 384,

                    width: "100%",
                    backgroundImage: `url(${portadas[0].img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    boxShadow: 10,
                    "@keyframes change": {
                        "0%": {
                            backgroundImage: `url(${portadas[0].img})`,
                        },
                        "30%": {
                            backgroundImage: `url(${portadas[1].img})`,
                        },
                        "35%": {
                            backgroundImage: `url(${portadas[2].img})`,
                        },
                        "65%": {
                            backgroundImage: `url(${portadas[3].img})`,
                        },
                        "70%": {
                            backgroundImage: `url(${portadas[4].img})`,
                        },
                        "75%": {
                            backgroundImage: `url(${portadas[5].img})`,
                        }
                    },
                    animation: "change 50s infinite ease-in-out",
                    position: "relative",

                }}
            >


                <Grid item container lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        sx={{
                            background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4374124649859944) 73%, rgba(0,0,0,0) 100%)',
                            minHeight: 100,
                            pb: 3,
                            pt: 6,
                            pl: sCell ? 3 : 4,
                            pr: sCell ? 3 : 4
                        }}
                    >

                        <Typography sx={{
                            fontSize: sCell ? 12 : 14,
                            color: '#fff',
                            textOverflow: "ellipsis",
                            textAlign: "justify",
                        }}>Se encuentra en el cantón Otavalo, creada el 12 de octubre de 1886... su población es de
                            8476 habitantes. La parroquia tiene 25 comunidades y una cabecera municipal con 6
                            barrios, su temperatura oscila los 9º y 15ºC </Typography>

                    </Grid>
                </Grid>


            </Grid>
        )
    }

    useEffect(() => {
        obtenerCol('portadas').then((dox) => {
            if (dox.res) {
                setPortadas(dox.data)
            }

        })
    }, [])
    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 0 : 10}}
            >

                {sCell &&

                    <Grid item container lg={6} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 2, zIndex: 2}}>
                        <YouTube
                            videoId={"ZzN6lAgKvaw"} // defaults -> null
                            id={"ZzN6lAgKvaw"} // defaults -> null
                            title={"GAD Parroquial"}
                            opts={opts}
                            style={{
                                borderRadius: 0,
                                boxShadow: "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
                                background: masSM ? 'rgba(255, 255, 255, 0.50)' : '#fff',
                                backdropFilter: 'blur(0px)',
                                marginBottom: 20,
                                backgroundColor: '#000'
                            }}

                        />
                    </Grid>
                }


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 10, zIndex: 2}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={8}

                    >

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', mx: sCell ? 4 : 0}}>
                            <Fondo portadas={portadas}/>
                        </Grid>


                        {!sCell && <Grid item container lg={6} sm={12} xs={12}
                                         sx={{
                                             justifyContent:'center',
                                             marginTop: 0,
                                             zIndex: 2
                                         }}>
                            <YouTube
                                videoId={"ZzN6lAgKvaw"} // defaults -> null
                                id={"ZzN6lAgKvaw"} // defaults -> null
                                title={"GAD Parroquial"}
                                opts={opts}
                                style={{
                                    borderRadius: 0,
                                    boxShadow: "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
                                    background: masSM ? 'rgba(255, 255, 255, 0.50)' : '#fff',
                                    backdropFilter: 'blur(0px)',
                                    marginBottom: 20,
                                    backgroundColor: '#000'
                                }}
                            />

                        </Grid>
                        }


                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: masSM ? 'flex-start' : 'center', marginTop: sCell ? 0 : -8}}>
                            <Button
                                onClick={() => irUrl(LINKPDOT)}
                                variant={'contained'}
                                sx={{fontSize: 18, fontWeight: 600, px: 6, textAlign: 'left', py: 2}}
                            >
                                Descargar PDOT 2023
                            </Button>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: masSM ? 'flex-start' : 'center', marginTop: -8}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent={sCell ? 'center' : "flex-start"}
                                alignItems="center"
                                sx={{px: 4, marginTop: 2}}
                            >

                                <Grid item container lg={6} sm={0} xs={12} sx={{justifyContent: 'flex-start'}}>

                                </Grid>

                                <Grid item container lg={6} sm={12} xs={8}
                                      sx={{
                                          justifyContent: masSM ? 'flex-start' : 'center',
                                          marginTop: masSM ? -2 : 0,
                                          mr: 0
                                      }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        sx={{mr: masSM ? -7 : 0}}
                                    >
                                        {REDES.map((it, index) => {
                                            return (
                                                <ButtonBase
                                                    onClick={() => irUrl(it.url)}
                                                    sx={{m: 0.5, borderRadius: 2}}>
                                                    <it.icono variant={'Bold'} color={PRIMARIO} size={'2.6rem'}/>
                                                </ButtonBase>
                                            )
                                        })}


                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -50, zIndex: 1}}>
                    <img src={logo} width={'60%'} height={'auto'}/>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Portada;


const portadaDefault = [
    {
        img: fondo1,
        imgCelular: fondo1
    },
    {
        img: fondo2,
        imgCelular: fondo2
    },
    {
        img: fondo3,
        imgCelular: fondo3
    }, {
        img: fondo1,
        imgCelular: fondo1
    },
    {
        img: fondo2,
        imgCelular: fondo2
    },
    {
        img: fondo3,
        imgCelular: fondo3
    },
]

