/**************************************************
 * Nombre:       Tarjeta_Persona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Avatar, Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../Constantes";
import {theme} from "../../Tema";


const Tarjeta_Persona = ({valores}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Avatar src={valores.img} sx={{height: 120, width: 120, border: 8, borderColor: PRIMARIO}}/>
            </Grid>

            <Grid item lg={8} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid container item lg={12} sm={12} xs={12} sx={{justifyContent: masSM ? 'flex-start' : 'center'}}>
                        <Typography
                            sx={{
                                fontFamily: 'Playfair Display SC',
                                fontSize: masSM ? 22 : 22,
                                fontWeight: 700,
                                textAlign: masSM ? 'left' : 'center'
                            }}
                        >
                            {valores.nombre}
                        </Typography>
                    </Grid>

                    <Grid container item lg={12} sm={12} xs={12} sx={{justifyContent: masSM ? 'flex-start' : 'center'}}>
                        <Typography>{valores.cargo}</Typography>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )
}

export default Tarjeta_Persona;