/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CardBase, FechaComponente} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {
    Book,
    Box,
    Buildings,
    Flag,
    Lock,
    Lovely,
    Message,
    Message2,
    Mobile,
    Money,
    Moneys,
    Reserve,
    Sms, Text
} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {theme} from "../../Tema";
import {NUMWASS, PRIMARIO} from "../../Constantes";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {irUrl} from "../../Utilidades/irUrl";


const Tarjeta_Portada = ({valores, click}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <CardBase valores={valores} sombra={0} sx={{width: '100%'}}>

            <ButtonBase
                onClick={() => click(valores)}
                sx={{borderRadius: 2}}>

                <CardBase.Imagen Icono={Text} dato={"img"} tamano={'100%'}/>

            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Portada;