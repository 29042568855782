/**************************************************
 * Nombre:       Rendicion_Cuentas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {GRIS, ITEMSLOTAIP, MESES, PRIMARIO, YEARS} from "../../Constantes";
import {Calendar, Calendar1} from "iconsax-react";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Items from "../Tarjetas/Tarjeta_Items";
import Tarjetas_Lotaip from "../Tarjetas/Tarjetas_Lotaip";
import {theme} from "../../Tema";
import {LoadingButton} from "@mui/lab";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import LOTAIP from "../../Dashboard/Secciones/LOTAIP";
import {irArriba} from "../../Utilidades/irArriba";
import Tarjetas_Presupuesto from "../Tarjetas/Tarjetas_Presupuesto";
import {irUrl} from "../../Utilidades/irUrl";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";


const Presupuestos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [year, setYear] = useState(2024)
    const [wheres, setWheres] = useState([])
    const {data, cargando} = useRecuperarColleccionListeningProg({col: 'presupuestos', wheres: wheres, orden: 'fecha'})


    useEffect(() => {
        setWheres([where('year', '==', parseInt(year))])
    }, [year])

    useEffect(() => {
        irArriba()
        setYear(2024)
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            fontSize: sCell ? 30 : 36,
                            fontWeight: 700,
                            color: PRIMARIO
                        }}
                    >
                        PRESUPUESTO
                    </Typography>
                </Grid>


                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 6}}>
                    <Typography sx={{fontSize: sCell ? 18 : 18, textAlign: 'justify'}}>
                        Difusión de la Información Pública.- Por la transparencia en la gestión administrativa que están
                        obligadas a observar todas las instituciones del Estado que conforman el sector público en los
                        términos del artículo 118 (225) de la Constitución Política de la República y demás entes
                        señalados en el artículo 1 de la presente Ley, difundirán a través de un portal de información o
                        página web, así como de los medios necesarios a disposición del público, implementados en la
                        misma institución, la siguiente información mínima actualizada, que para efectos de esta Ley, se
                        la considera de naturaleza obligatoria (Ley Orgánica de Transparencia y Acceso a la Información
                        Pública).
                        <br/>
                    </Typography>
                </Grid>


                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >

                        <Grid item lg={4} sm={4} xs={12}>
                            <Typography
                                sx={{
                                    fontFamily: 'Playfair Display SC',
                                    fontSize: 24,
                                    fontWeight: 700,
                                }}
                            >
                                Consultar Presupuestos
                            </Typography>
                        </Grid>

                        <Grid item lg={2} sm={4} xs={6}>
                            <TextField
                                label={'Año'}
                                select
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Calendar color={GRIS} variant={"Bold"}/>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                            >
                                {YEARS.map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>


                        <Grid item lg={2} sm={12} xs={6}>
                            {cargando &&
                                <CircularProgress size={40}/>
                            }
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{px: 4}}
                    >


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Ho_ManejoTargetas Card={Tarjetas_Presupuesto} data={data} spacing={6}/>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Presupuestos;


