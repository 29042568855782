/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO, DATOS, REDES, SECCIONES} from "../Constantes";
import logo from '../Recursos/logooscuro.png'
import {theme} from "../Tema";
import {irUrl} from "../Utilidades/irUrl";
import Login from "./Login/Login";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import {useNavigate} from "react-router-dom";


const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const {Dialogo, abrir} = useDialogo({Componente: Login})
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: ACENTO, py: 6, marginTop: 10}}
        >
            <Dialogo/>

            <Grid item lg={5} sm={6} xs={12} sx={{pl: sCell ? 4 : 8}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography
                            sx={{fontFamily: 'Playfair Display SC', color: '#fff', fontWeight: 800, fontSize: 24}}>
                            Links Rapidos<br/>
                        </Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {SECCIONES.map((it, index) => {
                                return (
                                    <Grid item lg={6} sm={12} xs={12}>
                                        <ButtonBase
                                            onClick={() => navigate(it.replaceAll(' ', '_'))}
                                            sx={{px: 0}}>
                                            <Typography sx={{color: "#fff"}}>{it}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                            <Grid item lg={12} sm={12} xs={12}>
                                <ButtonBase
                                    onClick={() => abrir()}
                                    sx={{px: 0}}>
                                    <Typography
                                        sx={{
                                            color: '#fff',
                                        }}>
                                        {'Administración'}</Typography>
                                </ButtonBase>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={4} sm={6} xs={12} sx={{marginTop: sCell ? 2 : 0, px: sCell ? 4 : 0}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography
                            sx={{fontFamily: 'Playfair Display SC', color: '#fff', fontWeight: 800, fontSize: 24}}>
                            Contactos<br/>
                        </Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {DATOS.map((it, index) => {
                                return (
                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Typography sx={{color: "#fff"}}>{it}</Typography>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 3}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"

                        >

                            {REDES.map((it, index) => {
                                return (
                                    <Grid item lg={1} sm={2} xs={2}>
                                        <ButtonBase
                                            onClick={() => irUrl(it.url)}
                                        >
                                            <it.icono variant={'Bulk'} color={'#fff'} size={masSM ? '2rem' : '3rem'}/>
                                        </ButtonBase>

                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={3} sm={12} xs={12}
                  sx={{justifyContent: sCell ? 'center' : "flex-end", marginTop: masSM ? 1 : 6, px: sCell ? 2 : 0}}>
                <img src={logo} width={sCell ? '60%' : 300} height={'auto'} alt={'logo'}
                     style={{
                         paddingLeft: 30,
                         paddingRight: 100,
                         backgroundColor: '#fff',
                         paddingTop: 30,
                         paddingBottom: 30,
                         borderBottomLeftRadius: 40,
                         borderTopLeftRadius: 40,
                         borderBottomRightRadius: sCell ? 40 : 0,
                         borderTopRightRadius: sCell ? 40 : 0,
                     }}/>
            </Grid>

        </Grid>
    )
}

export default Footer;