/**************************************************
 * Nombre:       ApartadoNoticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../../Constantes";
import {theme} from "../../../Tema";
import {formatoFecha} from "../../../Utilidades/formatoFecha";


const ApartadoGrande = ({noticia}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Playfair Display SC',
                                            fontSize: sCell ? 40 : 50,
                                            fontWeight: 800,
                                            color: '#00601520',
                                            lineHeight: 1.1
                                        }}>SAN
                                        JOSE
                                        DE <br/>
                                        QUICHINCHE</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: sCell ? -8 : -10.4}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Playfair Display SC',
                                            fontSize: sCell ? 36 : 42,
                                            fontWeight: 800,
                                            lineHeight: 1.1
                                        }}>Noticias</Typography>
                                </Grid>

                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            backgroundColor: '#F7F7F7',
                            boxShadow: 4,
                            py: sCell ? 2 : 4,
                            pl: sCell ? 2 : 4,
                            pr: sCell ? 2 : 0
                        }}
                    >

                        <Grid item lg={5} sm={12} xs={12} sx={{border: 20, borderColor: '#fff'}}>
                            <img src={noticia.img} width={'100%'} height={'auto'}/>
                        </Grid>

                        <Grid item lg={7} sm={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item lg={12} sm={12} xs={12} sx={{backgroundColor: PRIMARIO, px: 3, py: 1}}>
                                    <Typography sx={{color: '#fff'}}>{noticia.fecha && formatoFecha(noticia.fecha)}</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{px: masSM ? 4 : 2}}
                                    >

                                        <Grid item lg={12} sm={12} xs={12}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Playfair Display SC',
                                                    fontSize: sCell ? 24 : 34,
                                                    fontWeight: 700,
                                                    lineHeight: 1.1
                                                }}
                                            >{noticia.titulo}</Typography>
                                        </Grid>

                                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    textAlign: 'justify'
                                                }}
                                            >{noticia.parrafo}</Typography>
                                        </Grid>


                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}
                              sx={{marginTop: 4, px: masSM ? 0 : 2, marginBottom: sCell ? 4 : 0}}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    textAlign: 'justify'
                                }}
                            >{noticia.contenido}
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default ApartadoGrande;