/**************************************************
 * Nombre:       TurismoGastronomia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import DetalleItem from "./Componentes/DetalleItem";
import Catalogo from "./Componentes/Catalogo";
import FechasImportantes from "./Componentes/FechasImportantes";
import RegistraNegocio from "./Componentes/RegistraNegocio";
import {theme} from "../../Tema";
import {useParams} from "react-router-dom";
import {irArriba} from "../../Utilidades/irArriba";
import {useObtenerLocation} from "../../Utilidades/useObtenerLocation";
import ListaItems from "./Componentes/ListaItems";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import logo from "../../Recursos/logooscuro.png";


const TurismoGastronomia = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const parms = useParams()
    const [item, setItem] = useState({})
    const {nombre} = useObtenerLocation()

    useEffect(() => {


        if (nombre) {


            let col = collection(fire, 'lugares')
            let q = query(col, where('categoria', '==', nombre))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data) {
                    setItem(dox.data[0])
                }
            })

        }

        irArriba()


    }, [nombre])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 16}}>
                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={500}
                    data-aos-duration="1000"
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <img src={logo} width={500} height={'auto'} style={{opacity: 0.2}}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: "center", marginTop: masSM ? -12.4 : -10}}>
                        <Typography
                            sx={{
                                fontFamily: 'Playfair Display SC',
                                fontSize: 42,
                                fontWeight: 800,
                                lineHeight: 1.1
                            }}>{nombre}</Typography>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 10}}>
                <ListaItems tipo={nombre}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", backgroundColor: '#F5F5F5', marginTop: 16}}>
                <FechasImportantes/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <RegistraNegocio/>
            </Grid>


        </Grid>
    )
}

export default TurismoGastronomia;
