/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import {ACENTO} from "../../Constantes";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {ClipboardExport, Hashtag, Location, ReceiptItem, Reserve} from "iconsax-react";
import Tarjeta_Noticia from "../Tarjetas/Tarjeta_Noticia";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Noticia from "../Dialogos/Dialogo_Noticia";
import Tarjeta_Potencial from "../Tarjetas/Tarjeta_Potencial";
import Dialogo_Potenciales from "../Dialogos/Dialogo_Potenciales";
import Dialogo_lotaip from "../Dialogos/Dialogo_lotaip";
import Tarjeta_Lotaip from "../Tarjetas/Tarjeta_Lotaip";
import Dialogo_Presupuesto from "../Dialogos/Dialogo_Presupuesto";
import Tarjeta_Presupuesto from "../Tarjetas/Tarjeta_Presupuesto";
import {theme} from "../../Tema";


const Presupuesto = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Presupuesto})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'presupuestos',
        wheres: wheres,

    })


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={6} sm={3} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>Presupuestos</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={9} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            sx={{color: '#fff', fontSize: 16, px: sCell ? 2 : 4, borderRadius: 2}}
                            onClick={() => abrir()}
                            startIcon={<Hashtag variant={'Bold'}/>}
                        >{sCell ? 'Nuevo' : 'Nuevo Presupuesto'}</Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Presupuesto} data={data} click={abrir}/>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button
                    color={'secondary'}
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{color: ACENTO}}>Cargar Mas</Button>
            </Grid>


        </Grid>
    )
}

export default Presupuesto;