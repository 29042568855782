/**************************************************
 * Nombre:       RegistraNegocio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Registro from "../../Formularios/Formulario_Registro";
import Formulario_PQRS from "../../Formularios/Formulario_PQRS";
import {theme} from "../../../Tema";
import {verificarCamposObligatorios} from "../../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const Pqrs = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({Form: Formulario_PQRS, nombre: 'registro'})
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (!verificarCamposObligatorios(entidad, ['nombre', 'correo', 'mensaje'])) {
            return;
        }

        setCargando(true)
        entidad.fecha = new Date().getTime()
        guardarDoc('pqrs', entidad).then((dox) => {
            if (dox.res) {
                alert("Pqrs enviada con exito\nPronto le responderemos")
                limpiarEntidad()
            } else {
                alert("Paso algo inesperado, por favor intentelo mas tarde\nERR:PQR001")
            }
            setCargando(false)
        })

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 20}}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontWeight: 800,
                            fontSize: sCell ? 28 : 32
                        }}>
                        PQRS
                    </Typography>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{marginTop: 6}}
                >

                    <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                        <Typography
                            sx={{
                                fontSize: sCell ? 18 : 20,
                                textAlign: 'justify'
                            }}
                        >
                            Todas las peticiones, quejas, reclamos y sugerencias seran atendias por este medio, estamos
                            dispuestos a escuchar a cada persona de nuestra parroquia o visitante.
                        </Typography>
                    </Grid>

                </Grid>


                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Formulario {...props} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        loading={cargando}
                        variant={'outlined'}
                        sx={{px: 4, borderRadius: 6}}>Enviar</LoadingButton>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Pqrs;
