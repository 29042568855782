/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import {ACENTO} from "../../Constantes";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {ClipboardExport, ReceiptItem, Reserve} from "iconsax-react";
import Tarjeta_Noticia from "../Tarjetas/Tarjeta_Noticia";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Noticia from "../Dialogos/Dialogo_Noticia";
import Tarjeta_Potencial from "../Tarjetas/Tarjeta_Potencial";
import Dialogo_Potenciales from "../Dialogos/Dialogo_Potenciales";


const Potenciales = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Potenciales})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'potenciales',
        wheres: wheres,

    })


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={6} sm={3} xs={6}>
                        <Typography sx={{fontSize: 24, fontWeight: 800}}>Negocios Potenciales</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={9} xs={6} sx={{justifyContent: "flex-end"}}>

                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Potencial} data={data} click={abrir} pxCell={0}/>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button
                    color={'secondary'}
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{color: ACENTO}}>Cargar Mas</Button>
            </Grid>


        </Grid>
    )
}

export default Potenciales;