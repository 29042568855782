// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// internationalmd@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyBAdg1TopCMj0vk_mcFkSqcTkQDFv2vHJU",
    authDomain: "san-jose-quichinche.firebaseapp.com",
    projectId: "san-jose-quichinche",
    storageBucket: "san-jose-quichinche.appspot.com",
    messagingSenderId: "1093587008503",
    appId: "1:1093587008503:web:2d146cfdb80f31e6218cf2",
    measurementId: "G-TK80VB9BYG"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)