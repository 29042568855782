/**************************************************
 * Nombre:       Tarjeta_Items
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {click} from "@testing-library/user-event/dist/click";


const Tarjeta_Items = ({valores, click}) => {

    return (
        <ButtonBase
            onClick={() => click(valores)}
            sx={{width: '100%', borderRadius: 0, textAlign: 'left'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
            >
                <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={valores.img} width={'100%'} height={'auto'}/>
                </Grid>

                <Grid item lg={8} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography
                                sx={{
                                    fontFamily: 'Playfair Display SC',
                                    fontWeight: 800,
                                    fontSize: 22
                                }}
                            >{valores.nombre}</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{
                                lineHeight: 1.2,
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 4,
                                textOverflow: "ellipsis",
                                textAlign: 'justify'
                            }}>{valores.descripcion}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>
        </ButtonBase>
    )
}

export default Tarjeta_Items;