export const formatoFecha = (fecha) => {

    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    let fech = new Date(fecha)

    return fech.getDate() + ' De ' + months[fech.getMonth()] + ' ' + fech.getFullYear()


}