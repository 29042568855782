/**************************************************
 * Nombre:       Tarjetas_Lotaip
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import des from '../../Recursos/imgpdf.svg'
import {PRIMARIO} from "../../Constantes";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";

const Tarjetas_Lotaip_Item = ({valores}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={1} sm={1} xs={1}>

            </Grid>


            <Grid item lg={7} sm={7} xs={7}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={1} sm={1} xs={2}>
                        <Typography sx={{
                            color: PRIMARIO,
                            fontSize: sCell ? 16 : 20,
                            fontWeight: 600,
                            lineHeight: 1.4
                        }}>{valores.letra}</Typography>
                    </Grid>

                    <Grid item lg={9} sm={9} xs={8}>
                        <Typography sx={{fontSize: sCell ? 14 : 16}}>
                            {valores.texto}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "flex-end"}}>
                {valores.archivo &&
                <ButtonBase onClick={() => irUrl(valores.archivo)}>
                    <img src={des} width={sCell ? 30 : 50} height={'auto'}/>
                </ButtonBase>
                }
            </Grid>


        </Grid>
    )
}

export default Tarjetas_Lotaip_Item;