/**************************************************
 * Nombre:       MisionVision
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../../Constantes";
import {theme} from "../../../Tema";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Comision from "../../Tarjetas/Tarjeta_Comision";
import useRecuperarColleccionListeningProg from "../../../Servicios/BD/useRecuperarColleccionListeningProg";
import useRecuperarColleccionListeningToda from "../../../Servicios/BD/useRecuperarColleccionListeningToda";


const MisionVision = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningToda({
        col: 'comisiones',
        orden: 'nombre',
        direccion: 'asc',
        wheres: wheres
    })

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
                spacing={8}
            >

                {items.map((it, index) => {
                    return (
                        <Grid item lg={6} sm={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <Typography sx={{
                                        fontFamily: 'Playfair Display SC',
                                        color: PRIMARIO,
                                        fontWeight: 800,
                                        fontSize: sCell ? 28 : 32
                                    }}> {it.nombre}</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 2}}>
                                    <Typography sx={{textAlign: 'justify', fontSize: 18}}> {it.descripcion}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                })}

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Typography sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontWeight: 800,
                            fontSize: sCell ? 28 : 32
                        }}> Comisiones</Typography>
                    </Grid>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Ho_ManejoTargetas data={data} Card={Tarjeta_Comision} spacing={3}/>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default MisionVision;

const items = [
    {
        nombre: 'Misión',
        descripcion: 'Optimizar los recursos y priorizar la ejecución de obras, con el afán de proveer a la ciudadanía con respuestas reales a sus necesidades. Empoderarse del trabajo eficiente y eficaz de la administración del gobierno parroquial, con dedicación y responsabilidad social, fomentando la cogestión para alcanzar una parroquia justa y equitativa, que brinde espacios a todos los actores sociales y dote de servicios de calidad y calidez a todos sus habitantes.   '
    },
    {
        nombre: 'Visión',
        descripcion: 'Nuestra parroquia San José de Quichinche para el año 2027 será conocida como una parroquia referente en la gestión social de calidad en armonía con la Pacha Mama, respetando los derechos individuales, colectivos y principios culturales ama killa, ama shua, ama llulla, con el enfoque pluricultural  y multiétnico, una parroquia líder en el desarrollo y fomento de micro-emprendimientos, al desarrollo económico equitativo de sus habitantes, un ambiente en el que se incluyan a los grupos de atención prioritaria en la sociedad y programas en los que las expresiones culturales y artísticas además sean fomentadas, respetando las cotumbres y tradiciones que contribuyan a lograr el (Sumak Kawsay).'
    }
]