/**************************************************
 * Nombre:       BotonItem
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {ButtonBase, Collapse, Grid, Menu, MenuItem, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ACENTO} from "../../../Constantes";


const BotonItem = ({titulo, items}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)


    const irSeccion = (it) => {
        setOpen(false)
        navigate("/" + it.replaceAll(" ", '_'))

    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <ButtonBase

                    sx={{
                        transition: 'all .2s ease-in-out',
                        borderRadius: 0,
                        px: 2,
                        borderBottom: 2,
                        borderColor: '#00000000',
                    }}>
                    <Typography
                        sx={{color: open ? '#ffffff50' : '#fff', fontSize: 16, fontWeight: 500,}}>{titulo}</Typography>
                </ButtonBase>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>


                <Collapse in={open} sx={{position: 'absolute', zIndex: 5, width: 250}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        sx={{backgroundColor: ACENTO, marginTop: 0}}

                    >

                        {items.map((it, index) => {
                            return (
                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                    <MenuItem
                                        sx={{px: 2, color: '#fff',}}
                                        key={index} onClick={() => irSeccion(it)}>{it}</MenuItem>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Collapse>
            </Grid>

        </Grid>
    )
}

export default BotonItem;