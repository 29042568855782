/**************************************************
 * Nombre:       Tarjetas_Presupuesto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {irUrl} from "../../Utilidades/irUrl";
import des from "../../Recursos/imgpdf.svg";
import {theme} from "../../Tema";


const Tarjetas_Presupuesto = ({valores}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
        >

            <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                <img src={valores.img} width={'90%'} height={'auto'} style={{borderRadius: 10}}/>
            </Grid>

            <Grid item lg={8} sm={7} xs={12} sx={{marginTop: sCell ? 4 : 0}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{fontWeight: 600, fontSize: 24}}>{valores.titulo}</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                        <Typography sx={{
                            textAlign: 'justify', display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 5,
                            textOverflow: "ellipsis",
                        }}>
                            {valores.parrafo}
                        </Typography>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item container lg={1} sm={2} xs={12}
                  sx={{justifyContent: sCell ? 'center' : "flex-end", marginTop: sCell ? 4 : 0}}>
                <ButtonBase
                    onClick={() => irUrl(valores.archivo)}
                >
                    <img src={des} width={sCell ? 80 : 60} height={'auto'}/>
                </ButtonBase>
            </Grid>


        </Grid>
    )
}

export default Tarjetas_Presupuesto;