/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect} from 'react';
import {Grid} from "@mui/material";
import Barra from "./Barra/Barra";
import Home from "./Secciones/Home";
import Footer from "./Footer";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Noticias from "./Secciones/Noticias";
import TurismoGastronomia from "./Secciones/TurismoGastronomia";
import Rendicion_Cuentas from "./Secciones/Rendicion_Cuentas";
import Contactos from "./Secciones/Contactos";
import AOS from "aos";
import "aos/dist/aos.css";
import Lotaip from "./Secciones/Lotaip";
import Presupuesto from "../Dashboard/Secciones/Presupuesto";
import Presupuestos from "./Secciones/Presupuestos";
import Compras_Publicas from "./Secciones/Compras_Publicas";


const Pagina = () => {

    useEffect(() => {
        AOS.init({
            offset: 0
        });
        AOS.refresh();

    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <h1 style={{display: 'none'}}>San Jose de Quichince</h1>
            <h2 style={{display: 'none'}}>Parroquia San Jose de Quichinche</h2>
            <h2 style={{display: 'none'}}>Junta parroquial San Jose de Quichinche</h2>
            <h2 style={{display: 'none'}}>Fiestas San Jose de Quichinche</h2>
            <h2 style={{display: 'none'}}>Turismo San Jose de Quichinche</h2>


            <Router>

                <Barra/>

                <Routes>
                    <Route exact path={"/"} element={<Home/>}/>
                    <Route exact path={"*"} element={<Home/>}/>
                    <Route exact path={"/Home"} element={<Home/>}/>
                    <Route exact path={"/Noticias"} element={<Noticias/>}/>
                    <Route exact path={"/Noticias/:id"} element={<Noticias/>}/>
                    <Route exact path={"/Turismo_y_Gastronomia"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Hoteles"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Hoteles/:id"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Lugares"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Lugares/:id"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Restaurantes/"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Restaurantes/:id"} element={<TurismoGastronomia/>}/>
                    <Route exact path={"/Presupuesto"} element={<Presupuestos/>}/>
                    <Route exact path={"/Compras_Publicas"} element={<Compras_Publicas/>}/>
                    <Route exact path={"/Rendicion_de_Cuentas"} element={<Rendicion_Cuentas/>}/>
                    <Route exact path={"/Lotaip"} element={<Lotaip/>}/>
                    <Route exact path={"/Contactos"} element={<Contactos/>}/>

                </Routes>


                <Footer/>

            </Router>


        </Grid>
    )
}

export default Pagina;