import React, {createContext, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Pagina from "./Pagina/Pagina";
import {Grid} from "@mui/material";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import Dashboard from "./Dashboard/Dashboard";

export const usuarioContext = createContext();

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})


    const valoresProvider = {
        usuario
    }

    const {Provider} = usuarioContext;

    useEffect(() => {


        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: '100vw', overflowX: 'hidden'}}
        >

            {usuario ? <Provider value={valoresProvider}>
                    <Dashboard/>
                </Provider> :
                <Pagina/>}

        </Grid>
    );
}

export default App;
