/**************************************************
 * Nombre:       Tarjeta_Comision
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Collapse, Grid, Typography} from "@mui/material";
import {Add} from "iconsax-react";
import {PRIMARIO} from "../../Constantes";
import {useState} from "react";
import cuadro from "../../Recursos/tpm_cuadro.svg"

const Tarjeta_Comision = ({valores, click}) => {
    const [open, setOpen] = useState(false)

    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{borderRadius: 2, boxShadow: 4}}
        >


            <ButtonBase
                sx={{backgroundColor: PRIMARIO, borderRadius: 2, width: '100%', px: 2}}
                onClick={() => setOpen(!open)}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{p: 2}}
                >

                    <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 600}}>
                            {valores.nombre}
                        </Typography>
                    </Grid>

                    <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-end'}}>
                        <Add variant={'Bold'} color={'#fff'}/>
                    </Grid>

                </Grid>
            </ButtonBase>

            <Collapse in={open} sx={{width: '100%'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{p: 4}}
                    spacing={4}
                >

                    <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <img src={valores.img} width={'100%'} height={'auto'} style={{borderRadius: 10}}/>
                    </Grid>

                    <Grid item container lg={9} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{textAlign: 'justify'}} >
                            {valores.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -1}}>
                        <Typography sx={{fontWeight: 400, fontSize: 12, fontStyle: 'italic', textAlign: 'justify'}}>
                            {valores.detalle}
                        </Typography>
                    </Grid>

                </Grid>

            </Collapse>


        </Grid>

    )

}
export default Tarjeta_Comision