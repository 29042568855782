/**************************************************
 * Nombre:       Tarjetas_Lotaip
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import des from '../../Recursos/imgpdf.svg'
import {PRIMARIO} from "../../Constantes";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjetas_Lotaip_Item from "./Tarjetas_Lotaip_Item";
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";

const Tarjetas_Lotaip = ({valores}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={1} sm={1} xs={12}>
                <Typography sx={{
                    color: PRIMARIO,
                    fontSize: sCell ? 32 : 40,
                    fontWeight: 600,
                    lineHeight: 1.4
                }}>{valores.apartado}</Typography>
            </Grid>

            <Grid item lg={9} sm={9} xs={10}>
                <Typography sx={{textAlign: 'justify', fontSize: sCell ? 14 : 16}}>
                    {valores.descripcion}
                </Typography>
            </Grid>

            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "flex-end"}}>
                {valores.archivo &&
                <ButtonBase onClick={() => irUrl(valores.archivo)}>
                    <img src={des} width={sCell ? 40 : 60} height={'auto'}/>
                </ButtonBase>
                }
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas data={valores.items} Card={Tarjetas_Lotaip_Item} px={0} spacing={2}/>

                </Grid>
            </Grid>


        </Grid>
    )
}

export default Tarjetas_Lotaip;