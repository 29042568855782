/**************************************************
 * Nombre:       Formulario_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {FormBase, ImagenDimencion, InputCheck} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Message, Message2, Sms, Text, User} from "iconsax-react";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const Formulario_Comentarios = ({...props}) => {
    const [cargando, setCargando] = useState(false)


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIconFecha dato={'fecha'} label={"Fecha de Comentario"}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputCheck dato={'aprobado'} label={'Aprobado'}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} dato={'nombre'} label={"Nombre"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Sms} dato={'correo'} label={"Correo Electronico"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message2} dato={'comentario'} label={"Comentario"} lineas={8}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Comentarios;