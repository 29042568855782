/**************************************************
 * Nombre:       FechasImportantes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {PickersDay} from '@mui/x-date-pickers/PickersDay'
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import {DayCalendarSkeleton} from '@mui/x-date-pickers/DayCalendarSkeleton';
import {formatoFecha} from "../../../Utilidades/formatoFecha";
import {theme} from "../../../Tema";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";
import {escogerProximoEventoIndex} from "../../../Utilidades/escogerProximoEventoIndex";


const FechasImportantes = () => {
    const [escogida, setEscogida] = useState({})
    const [posicion, setPosicion] = useState(0)
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [fechas, setFechas] = useState([])


    function ServerDay(props) {
        const {day, outsideCurrentMonth, ...other} = props;
        const [isSelected, setIsSelected] = useState(false)

        const buscarFecha = () => {

            let fec = new Date(day.$y, day.$M, day.$D).getTime()
            let index = fechas.findIndex((e) => e.fecha === fec)
            if (index >= 0) {
                setPosicion(index)
            }
        }

        useEffect(() => {


            let fecha = new Date(day.$y, day.$M, day.$D)
            let i = fechas.findIndex((e, i) => {
                return e.fecha === fecha.getTime()
            })
            setIsSelected(i > -1)


        }, [props, fechas])
        return (
            <ButtonBase
                onClick={() => isSelected ? buscarFecha() : ''}
                sx={{backgroundColor: isSelected ? ACENTO : ''}}>
                <PickersDay   {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/>
            </ButtonBase>
        );
    }


    useEffect(() => {

        obtenerCol('fechas').then((dox) => {
            if (dox.res) {
                setFechas(dox.data)
                let inicial = escogerProximoEventoIndex(dox.data)
                setPosicion(inicial)
                setEscogida(dox.data[inicial])

            }
        })
    }, [])

    useEffect(() => {
        if (fechas.length > 0) {
            setEscogida(fechas[posicion])
        }

    }, [posicion])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 6, py: 6}}
            >


                <Grid
                    component={"div"}
                    data-aos="fade-right"
                    data-aos-delay={0}
                    data-aos-duration="1000"
                    item lg={12} sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontWeight: 800,
                            fontSize: sCell ? 28 : 32
                        }}>
                        Fechas Importantes<br/>
                        <span><Grid sx={{
                            borderTop: 6,
                            width: 80,
                            borderColor: ACENTO,
                            borderRadius: 8,
                            marginTop: 0.5
                        }}/></span>
                    </Typography>
                </Grid>


                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Grid

                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={500}
                            data-aos-duration="1000"
                            item container lg={4} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                                <DateCalendar
                                    defaultValue={dayjs(new Date()) ? dayjs(new Date()) : ''}
                                    renderLoading={() => <DayCalendarSkeleton/>}
                                    slots={{
                                        day: ServerDay,
                                    }}

                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item lg={2} sm={12} xs={12}>

                        </Grid>

                        <Grid item lg={6} sm={12} xs={12}>
                            <Grid
                                component={"div"}
                                data-aos="fade-left"
                                data-aos-delay={1000}
                                data-aos-duration="1000"
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography> {escogida && escogida.fecha && formatoFecha(escogida.fecha)}</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Playfair Display SC',
                                            fontWeight: 800,
                                            fontSize: sCell ? 26 : 30
                                        }}>
                                        {escogida && escogida.nombre}
                                    </Typography>
                                </Grid>


                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Typography
                                        sx={{
                                            fontSize: sCell ? 16 : 18,
                                            textAlign: 'justify',
                                        }}>{escogida && escogida.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={4} sm={3} xs={6}
                                      sx={{justifyContent: "flex-start", marginTop: 3}}>
                                    {posicion > 0 &&
                                    <Button
                                        onClick={() => setPosicion(posicion - 1)}
                                        variant={"outlined"} sx={{borderRadius: 6, px: 4}}>Anterior</Button>
                                    }
                                </Grid>

                                <Grid item container lg={6} sm={4} xs={6}
                                      sx={{justifyContent: "flex-start", marginTop: 3}}>
                                    {(posicion + 1) < fechas.length &&
                                    < Button
                                        onClick={() => setPosicion(posicion + 1)}
                                        variant={"contained"} sx={{borderRadius: 6, px: 4}}>Siguiente</Button>
                                    }
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default FechasImportantes;

const diasfestivos = [new Date(2023, 9, 30).getTime(), new Date(2023, 9, 29).getTime(),]


const fechasImportantes = [
    {
        fecha: new Date(2023, 3, 19).getTime(),
        titulo: 'Las "Fiestas de San José", fiestas patronales.',
        descripcion: 'Pregón de fiestas, con la presentación de orquestas populares. La parroquia Quichinche inicia a celebra sus fiestas PATRONALES según los organizadores se programan varias actividades sociales, deportivas y culturales. Se realiza el desfile de la alegría por las principales calles de la población y en la noche UN baile popular en el parque principal',

    },
    {
        fecha: new Date(2023, 3, 20).getTime(),
        titulo: 'Peregrinación con la Imagen del Patriarca San José',
        descripcion: 'Peregrinación con la Imagen del Patriarca San José, desde el Santuario Nacional del Señor de las Angustias en Otavalo',

    },
    {
        fecha: new Date(2023, 3, 20).getTime(),
        titulo: 'Concierto  de Fiestas, en el (Parque de la localidad)',
        descripcion: 'Concierto  de Fiestas, en el (Parque de la localidad)',

    },
    {
        fecha: new Date(2023, 3, 21).getTime(),
        titulo: 'Procesión con el Glorioso Patriarca San José',
        descripcion: 'Procesión con el Glorioso Patriarca San José, domingo, por las principales calles de la parroquia',

    },
    {
        fecha: new Date(2023, 3, 21).getTime(),
        titulo: 'Concierto de Fin de Fiestas.',
        descripcion: 'Concierto  de Fiestas, en el (Parque de la localidad)',

    },
    {
        fecha: new Date(2023, 10, 29).getTime(),
        titulo: 'Las "Fiestas de Octubre", fundación parroquial.',
        descripcion: ' Homenaje a la parroquia por su creación. Entre los numero destacados  está la coronación y exaltación de la reina en el Parque Domingo Arellano.  Últimamente se efectúan ferias solidarias y gastronómicas con la presencia de las comunidades y sus comidas típicas. En este mismo día se efectúa el desfile cívico con la participación de las unidades educativas de la Parroquia, para posteriormente llevar a cabo una sesión solemne con la presencia de las principales autoridades del cantón y la provincia.  Se oficia una  misa en honor a los a la Fundación Parroquial. También en los últimos años se realiza  una carrera de caballos con obstáculos. Finalizan las fiestas con la presentación de orquestas.',

    },
]
