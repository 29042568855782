/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useState, useEffect} from 'react';
import {Grid} from "@mui/material";
import {
    Calendar1,
    ClipboardExport, Flag,
    HambergerMenu, Hashtag, Image, Location, Messages, Money, MoneySend, UserAdd, UserSquare, Warning2,
} from "iconsax-react";
import {salirAunth} from "../Servicios/Auth/funSalir";
import logo from '../Recursos/logofondoblanco.svg'
import logoLargo from '../Recursos/logolargoverde.png'
import fondo from '../Recursos/fondoperfil.svg'
import {DashboardBase, Barra} from "../Modulo_Dashboard/Componentes/C_Dashboard";
import {usuarioContext} from "../App";
import Noticias from "./Secciones/Noticias";
import Fechas from "./Secciones/Fechas";
import Lugares from "./Secciones/Lugares";
import Potenciales from "./Secciones/Potenciales";
import Comentarios from "./Secciones/Comentarios";
import LOTAIP from "./Secciones/LOTAIP";
import Portada from "./Secciones/Portada";
import Pqrs from "../Pagina/Secciones/Componentes/Pqrs";
import PQRS from "./Secciones/PQRS";
import Presupuesto from "./Secciones/Presupuesto";
import Comisiones from "./Secciones/Comisiones";
import Rendicion_Cuentas from "./Secciones/Rendicion_Cuentas";


const Dashboard = () => {
    const cData = useContext(usuarioContext);
    const [credenciales, setCredenciales] = useState([])


    useEffect(() => {
        if (cData && cData.usuario) {
            setCredenciales(cData.usuario.credenciales)
        }
    }, [cData])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DashboardBase componentes={componentes} componentesID={componentesID}>

                <DashboardBase.Barra>

                    <Grid item lg={4} sm={4} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={4}>
                                <Barra.OpenDrawer>
                                    <HambergerMenu color={"#fff"}/>
                                </Barra.OpenDrawer>
                            </Grid>

                            <Grid item lg={10} sm={8} xs={8}>
                                <Barra.Titulo/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={8} sm={8} xs={6} sx={{justifyContent: "flex-end"}}>
                        <img src={logoLargo} width={90}/>
                    </Grid>


                </DashboardBase.Barra>

                <DashboardBase.Drawer credenciales={credenciales} logo={logo} usuario={cData.usuario} fondo={fondo}
                                      funSalir={salirAunth}/>

                <DashboardBase.Main/>

            </DashboardBase>


        </Grid>
    )
}

export default Dashboard;


const componentes = [
    {
        seccion: <Portada/>,
        credencial: "Portada",
        icono: <Image variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Image variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Noticias/>,
        credencial: "Noticias",
        icono: <ClipboardExport variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <ClipboardExport variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <Fechas/>,
        credencial: "Fechas",
        icono: <Calendar1 variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Calendar1 variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <Lugares/>,
        credencial: "Lugares",
        icono: <Location variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Location variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <Potenciales/>,
        credencial: "Potenciales",
        icono: <UserAdd variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserAdd variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <Comentarios/>,
        credencial: "Comentarios",
        icono: <Messages variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Messages variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <LOTAIP/>,
        credencial: "Lotaip",
        icono: <Hashtag variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Hashtag variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <Presupuesto/>,
        credencial: "Presupuesto",
        icono: <Money variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Money variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    }, {
        seccion: <PQRS/>,
        credencial: "Pqrs",
        icono: <Warning2 variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Warning2 variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Comisiones/>,
        credencial: "Comisiones",
        icono: <Flag variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Flag variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },
    {
        seccion: <Rendicion_Cuentas/>,
        credencial: "Cuentas",
        icono: <MoneySend variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <MoneySend variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
    },


]


const componentesID = []