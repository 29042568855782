/**************************************************
 * Nombre:       ApartadoNoticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../Constantes";
import {theme} from "../../Tema";
import {useNavigate} from 'react-router-dom'
import {formatoFecha} from "../../Utilidades/formatoFecha";


const Tarjeta_Noticias = ({valores: item, difiere = false}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 0 : 3}}
            >


                <Grid
                    component={"div"}
                    data-aos="fade-left"
                    data-aos-delay={500}
                    data-aos-duration="1000"
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        backgroundColor: '#F7F7F7',
                        boxShadow: 4,
                        py: masSM ? 4 : 2,
                        pl: masSM ? 4 : 2,
                        pr: masSM ? 0 : 2
                    }}
                >

                    <Grid item lg={5} sm={12} xs={12} sx={{border: 20, borderColor: '#fff'}}>
                        <img src={item.img} width={'100%'} height={'auto'}/>
                    </Grid>

                    <Grid item lg={7} sm={12} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item lg={12} sm={12} xs={12} sx={{backgroundColor: PRIMARIO, px: 3, py: 1}}>
                                <Typography sx={{color: '#fff'}}>{formatoFecha(item.fecha)}</Typography>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    sx={{px: 4}}
                                >

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Playfair Display SC',
                                                fontSize: masSM ? 40 : 24,
                                                fontWeight: 700,
                                                lineHeight: 1.1,

                                            }}
                                        >{item.titulo}</Typography>
                                    </Grid>

                                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                        <Typography
                                            sx={{
                                                fontSize: masSM ? 18 : 16,
                                                textAlign: 'justify',
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 5,
                                                textOverflow: "ellipsis",
                                            }}
                                        >{item.parrafo}</Typography>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? 'center' : "flex-end", marginTop: 2}}>
                                        <Button
                                            onClick={() => navigate(item.idNoticia ? '/Noticias/' + item.idNoticia : '/Noticias/' + item.id)}
                                            variant={'contained'} sx={{borderRadius: 6, px: 6}}>Ver mas</Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>

            </Grid>

        </Grid>
    )
}

export default Tarjeta_Noticias;