/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {ButtonBase, Drawer, Grid, Typography} from "@mui/material";
import logo from '../../../Recursos/logooscuro.png'
import {CloseSquare, HambergerMenu} from "iconsax-react";
import {ACENTO, PRIMARIO, REDES, SECCIONES, SECCIONESORDEN} from "../../../Constantes";
import {irUrl} from "../../../Utilidades/irUrl";
import {useNavigate} from 'react-router-dom'


const BarraCell = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{px: 3, py: 3}}
            >


                <Grid container item lg={6} sm={6} xs={6}>
                    <img src={logo} width={150} height={'auto'} alt={'Logo'} title={'Logo'}/>
                </Grid>

                <Grid item container lg={8} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                    <ButtonBase
                        onClick={() => abrir()}
                        sx={{borderRadius: 2, backgroundColor: ACENTO, p: 0.5}}>
                        <HambergerMenu color={'#fff'} size={'1.8rem'}/>
                    </ButtonBase>
                </Grid>

            </Grid>

            <Drawer
                anchor={'right'}
                open={open} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 250, p: 2}}
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <ButtonBase
                            onClick={() => cerrar()}
                            sx={{p: 0.4, borderRadius: 2}}>
                            <CloseSquare variant={'Bold'} color={'#fff'} size={'2rem'}/>
                        </ButtonBase>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {SECCIONESORDEN.map((it, index) => {
                                return (
                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: "center", marginTop: 3}}>
                                        <ButtonBase onClick={() => navigate('/' + it.replaceAll(' ', "_"))}>
                                            <Typography sx={{color: '#fff', fontSize: 20, fontWeight: 600}}>
                                                {it}
                                            </Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>


                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            {REDES.map((it, index) => {
                                return (
                                    <Grid item container lg={12} sm={3} xs={3} sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            onClick={() => irUrl(it.url)}
                                        >
                                            <it.icono variant={'Bold'} size={'2.5rem'} color={"#fff"}/>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>


                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 5}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{backgroundColor: '#fff', py: 2}}
                    >

                        <img src={logo} width={'150px'} height={'auto'}/>


                    </Grid>
                </Grid>

            </Drawer>

        </>
    )
}

export default BarraCell;