/*******************************************************
 * Nombre: Componentes Tarjetas
 * Descripcion : Tiene todos los templates del componente
 *               tarjeta contenidos en un CardBase
 * Libreirias:  Mui.com, iconsax-react,
 *
 * Autor: Luis Rosero
 * Tiempo:  4 hrs
 *******************************************************/
import {Avatar, ButtonBase, Divider, Grid, Typography} from "@mui/material";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {Calendar, Component} from "iconsax-react";


const cardContex = createContext()

const COLORICON = "#000"


export const CardBase = ({children, valores, estados = [], sombra = 6, radius = 2, p = 1}) => {

    const {Provider} = cardContex;

    const valoresProvider = {
        valores,
    }


    const getColor = (est) => {


        let col = "#fff";
        for (let i = 0; i < estados.length; i++) {
            if (est === estados[i].nombre) {
                col = estados[i].color
            }

        }

        return col;
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                p: p,
                boxShadow: sombra,
                borderRadius: radius,
                borderRight: estados.length > 0 ? 12 : 0,
                borderColor: estados.length > 0 ? getColor(valores.estado) : "none"
            }}


        >
            <Provider value={valoresProvider}>
                {children}
            </Provider>
        </Grid>
    )
}


export const BotonIndicador = ({Icono, color = "#3d3d3d", click}) => {
    const cData = useContext(cardContex)
    return (
        <ButtonBase sx={{p: 0.8, borderRadius: 2, backgroundColor: color, boxShadow: 4}}
                    onClick={() => click(cData.valores.id)}
        >
            <Icono color={"#fff"} size={"1.2rem"} variant={"Bold"}/>
        </ButtonBase>
    )
}

export const IconTexto = ({
                              Icono = Component,
                              pl = 10,
                              dato1 = '',
                              dato2 = '',
                              colorIcon = COLORICON,
                              dir = "flex-start",
                              array = false,
                              lineas = 1,
                              peso = 600,
                              size = 14,
                              color = "#3d3d3d"
                          }) => {
    const cData = useContext(cardContex).valores
    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="center"
        >


            <Typography
                sx={{
                    color: color,
                    fontWeight: peso,
                    fontSize: size,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",


                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{
                        fontWeight: peso,
                        marginRight: dato1 === '' ? 0 : 6,
                        color: color,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: lineas,
                        textOverflow: "ellipsis",
                    }}>{array ? cData[dato1].toString() : cData[dato1]}</span>
                {cData[dato2]}</Typography>


        </Grid>


    )
}

export const Texto = ({
                          pl = 10,
                          dato1 = '',
                          dato2 = '',
                          colorIcon = COLORICON,
                          dir = "flex-start",
                          array = false,
                          lineas = 1,
                          peso = 600,
                          size = 14,
                          color = "#3d3d3d"
                      }) => {
    const cData = useContext(cardContex).valores
    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="center"
        >


            <Typography
                sx={{
                    color: color,
                    fontWeight: peso,
                    fontSize: size,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",


                }}>
                <span
                    style={{
                        fontWeight: peso,
                        marginRight: dato1 === '' ? 0 : 6,
                        color: color,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: lineas,
                        textOverflow: "ellipsis",
                    }}>{array ? cData[dato1].toString() : cData[dato1]}</span>
                {cData[dato2]}</Typography>


        </Grid>


    )
}


export const IconTextoParrafo = ({
                                     pl = 10,
                                     lineas = 2,
                                     titulo = '',
                                     dato1 = '',
                                     dato2 = '',
                                     tituloColor = "#3d3d3d",
                                     peso = 600
                                 }) => {
    const cData = useContext(cardContex).valores
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: peso,
                    fontSize: 14,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: lineas,
                    textOverflow: "ellipsis",
                    textAlign: "left",


                }}>
                <span
                    style={{fontWeight: peso, marginRight: titulo === '' ? 0 : 6, color: tituloColor}}>{titulo}</span>
                {cData[dato1] ? cData[dato1] : "" + " " + cData[dato2] ? cData[dato2] : ""}</Typography>


        </Grid>


    )
}

export const IconTextoTitulo = ({
                                    Icono = Component,
                                    pl = 10,
                                    titulo = '',
                                    dato2 = '',
                                    colorIcon = "#3d3d3d",
                                    tituloColor = "#3d3d3d",
                                    booleano = false
                                }) => {
    const cData = useContext(cardContex).valores
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: 600,
                    fontSize: 14,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",

                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{fontWeight: 600, marginRight: titulo === '' ? 0 : 6, color: tituloColor}}>{titulo}</span>
                {booleano ? cData[dato2] === true : cData[dato2]}</Typography>


        </Grid>


    )
}

export const IconTextoMoneda = ({
                                    Icono = Component,
                                    pl = 10,
                                    dato1 = '',
                                    dato2 = '',
                                    colorIcon = "#3d3d3d",
                                    dir = "flex-start"
                                }) => {
    const cData = useContext(cardContex).valores

    const currency = function (number) {
        return new Intl.NumberFormat('en', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        }).format(number);
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: 600,
                    fontSize: 14,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",

                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{
                        fontWeight: 600,
                        marginRight: dato1 === '' ? 0 : 6,
                        color: "#3d3d3d"
                    }}>{cData[dato1] ? currency(cData[dato1]) : currency(0)}</span>
                {cData[dato2]}</Typography>


        </Grid>


    )
}

export const IconTextoFecha = ({
                                   Icono = Calendar,
                                   pl = 10,
                                   dato1 = '',
                                   dato2 = '',
                                   colorIcon = "#3d3d3d",
                                   sencillo = false,
                                   peso = 600,
                                   size = 14
                               }) => {
    const cData = useContext(cardContex).valores


    const setFecha = (e) => {

        let fecha
        try {
            fecha = e.toDate()
        } catch (err) {
            fecha = new Date(e)

        }

        if (sencillo) {
            return getFechaMin(fecha)
        } else {
            return getFechaSencillo(fecha)
        }


    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: peso,
                    fontSize: size,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",

                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{
                        fontWeight: peso,
                        marginRight: dato1 === '' ? 0 : 6,
                        color: "#3d3d3d"
                    }}>{cData[dato1] && setFecha(cData[dato1])}</span>
                {cData[dato2]}</Typography>


        </Grid>


    )
}


export const IconTextoFechaDate = ({
                                       Icono = Calendar,
                                       pl = 10,
                                       dato1 = '',
                                       dato2 = '',
                                       colorIcon = "#3d3d3d",
                                       sencillo = false
                                   }) => {
    const cData = useContext(cardContex).valores


    const setFecha = (e) => {
        if (sencillo) {
            return getFechaMin(e)
        } else {
            return getFechaSencillo(e)
        }


    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: 600,
                    fontSize: 14,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",

                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{
                        fontWeight: 600,
                        marginRight: dato1 === '' ? 0 : 6,
                        color: "#3d3d3d"
                    }}>{cData[dato1] && setFecha(cData[dato1])}</span>
                {cData[dato2]}</Typography>


        </Grid>


    )
}


export const IconTextoId = ({children, dato}) => {

    const cData = useContext(cardContex).valores


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Typography
                    sx={{
                        color: "#3d3d3d",
                        fontWeight: 600,
                        fontSize: 12,
                        textAlign: "left"
                    }}>
                    {cData[dato]}</Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12}>
                {children}
            </Grid>


        </Grid>


    )
}

export const Imagen = ({dato, tamano = "90%", direccion = 'flex-start'}) => {
    const refe = useRef()
    const {alto} = useGetAlto({refe})
    const cData = useContext(cardContex).valores

    return (
        <Grid
            container
            direction="row"
            justifyContent={direccion}
            alignItems="flex-start"
        >

            <img src={cData && cData[dato]} ref={refe} alt={dato}
                 style={{width: tamano, height: "auto", borderRadius: 8, display: "block"}}/>

        </Grid>
    )
}

export const AvatarCirculo = ({dato, size = 150}) => {
    const refe = useRef()
    const cData = useContext(cardContex).valores

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Avatar src={cData && cData[dato]} ref={refe} alt={dato}
                    style={{width: size, height: size}}/>

        </Grid>
    )
}


////////////////////Complejos////////////////////
export const AvatarCliente = ({}) => {
    const refe = useRef()
    const {alto} = useGetAlto({refe})
    const cData = useContext(cardContex).valores

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Grid item container lg={2} sm={2} xs={3} sx={{justifyContent: "center"}}>
                <Avatar src={cData.imgCliente} ref={refe} sx={{width: "70%", height: alto}}/>
            </Grid>


            <Grid item lg={10} sm={8} xs={9}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography
                            sx={{
                                color: "#3d3d3d",
                                fontWeight: 600,
                                fontSize: 16,
                                textAlign: "left"
                            }}>{cData.nombre}</Typography>
                    </Grid>
                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography
                            sx={{color: "#3d3d3d", fontWeight: 400, fontSize: 14, textAlign: "left"}}>
                            <span>{"Cc/Nit : "}</span>
                            {cData.cc}</Typography>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}

export const FechaComponente = ({children, sencillo = false, dato1 = '', dir = "flex-start"}) => {

    const cData = useContext(cardContex).valores

    const setFecha = (e) => {
        if (sencillo) {
            return getFechaMin(e)
        } else {
            return getFechaSencilloHora(e)
        }

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} justifyContent={dir}>
                <Typography
                    sx={{
                        color: "#3d3d3d",
                        fontWeight: 600,
                        fontSize: 11,
                        verticalAlign: "middle",
                        display: "inline-flex",
                        textAlign: "left",
                    }}
                >{cData[dato1] && setFecha(cData[dato1])}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} justifyContent={dir} sx={{marginTop: 0.2}}>
                {children}
            </Grid>

        </Grid>
    )
}

export const IconTextoArray = ({
                                   Icono = Component,
                                   pl = 10,
                                   dato1 = '',
                                   dato2 = '',
                                   colorIcon = "#3d3d3d",
                                   dir = "flex-start"
                               }) => {
    const cData = useContext(cardContex).valores
    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="center"
        >


            <Typography
                sx={{
                    color: "#3d3d3d",
                    fontWeight: 600,
                    fontSize: 14,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    textAlign: "left",

                }}>
                <Icono variant={"Bulk"} size={"1.2rem"} color={colorIcon} style={{marginRight: pl}}/>
                <span
                    style={{fontWeight: 600, marginRight: dato1 === '' ? 0 : 6, color: "#3d3d3d"}}>{cData[dato1]}</span>
                {cData[dato2] ? cData[dato2].length : 0}</Typography>


        </Grid>


    )
}

////////////////////Hook interno/////////////////
export const useGetAlto = ({refe}) => {
    const [alto, setAlto] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            if (refe !== null) {
                setAlto(refe.current.getBoundingClientRect().width)
            }
        }, 500)
    }, [refe])
    return {
        alto
    }
}

export const DividerTexto = ({texto, lg = 12, sm = 12, xs = 12}) => {

    return (
        <Grid item lg={12} sm={12} xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"

            >

                <Grid item lg={lg} sm={sm} xs={xs}>
                    <Typography sx={{fontSize: 14, color: "#6A6A6A"}}>{texto}</Typography>
                </Grid>

                <Grid item lg={12 - lg} sm={12 - sm} xs={12 - xs}>
                    <Divider sx={{width: "100%"}}/>
                </Grid>

            </Grid>
        </Grid>

    )
}

CardBase.IconTexto = IconTexto;
CardBase.Texto = Texto;
CardBase.IconTextoId = IconTextoId;

CardBase.IconTextoParrafo = IconTextoParrafo;
CardBase.IconTextoTitulo = IconTextoTitulo;
CardBase.IconTextoMoneda = IconTextoMoneda;
CardBase.IconTextoFecha = IconTextoFecha;
CardBase.IconTextoFechaDate = IconTextoFechaDate;

CardBase.Imagen = Imagen;
CardBase.BotonIndicador = BotonIndicador;
CardBase.FechaComponente = FechaComponente;
CardBase.AvatarCliente = AvatarCliente;
CardBase.IconTextoArray = IconTextoArray;
CardBase.AvatarCirculo = AvatarCirculo;

CardBase.DividerTexto = DividerTexto;


////////////////funciones///////////////////////
const getFechaSencillo = (date) => {


    try {
        let nombre = obtenerNombre(date.getDay())
        let dia = date.getDate();
        let mes = getMesPeq(date.getMonth());
        let ano = date.getFullYear();
        return nombre + ' ' + dia + " " + mes + " " + ano;
    } catch (e) {
        let nombre = obtenerNombre(date.toDate().getDay())
        let dia = date.toDate().getDate();
        let mes = getMesPeq(date.toDate().getMonth());
        let ano = date.toDate().getFullYear();
        return nombre + ' ' + dia + " " + mes + " " + ano;
    }


}

const getFechaSencilloHora = (date) => {

    let fecha

    try {
        fecha = date.toDate()
    } catch (e) {
        fecha = new Date(date)
    }


    try {
        let nombre = obtenerNombre(fecha.getDay())
        let dia = fecha.getDate();
        let mes = getMesPeq(fecha.getMonth());
        let ano = fecha.getFullYear();
        let hora = fecha.getHours()
        let min = fecha.getMinutes();
        if (min < 10) {
            min = "0" + min
        }
        return nombre + " " + dia + " " + mes + " " + ano;
    } catch (e) {
        let nombre = obtenerNombre(fecha.getDay())
        let dia = fecha.getDate();
        let mes = getMesPeq(fecha.getMonth());
        let ano = fecha.getFullYear();
        let hora = fecha.getHours()
        let min = fecha.getMinutes();
        if (min < 10) {
            min = "0" + min
        }
        return nombre + " " + dia + " " + mes + " " + ano;
    }


}


const obtenerNombre = (dia) => {
    switch (dia) {
        case 0 :
            return 'Domingo'
        case 1 :
            return 'Lunes'
        case 2 :
            return 'Martes'
        case 3 :
            return 'Miercoles'
        case 4 :
            return 'Jueves'
        case 5 :
            return 'Viernes'
        case 6 :
            return 'Sabado'
    }
}

const getFechaMin = (date) => {


    let fecha

    try {
        fecha = date.toDate()
    } catch (e) {
        fecha = new Date(date)
    }

    try {
        let dia = fecha;
        let mes = getMesPeq(fecha.getMonth());
        let ano = fecha.getFullYear();
        return dia + " " + mes;

    } catch (e) {
        let dia = fecha.getDate();
        let mes = getMesPeq(fecha.getMonth());

        return dia + " " + mes;
    }


}

const getMes = (num) => {
    switch (num) {
        case 0:
            return "Enero";
        case 1:
            return "Febrero";
        case 2:
            return "Marzo";
        case 3:
            return "Abril";
        case 4:
            return "Mayo";
        case 5:
            return "Junio";
        case 6:
            return "Julio";
        case 7:
            return "Agosto";
        case 8:
            return "Septiembre";
        case 9:
            return "Octubre";
        case 10:
            return "Noviembre";
        case 11:
            return "Diciembre";


    }
}

const getMesPeq = (num) => {
    switch (num) {
        case 0:
            return "Ene";
        case 1:
            return "Feb";
        case 2:
            return "Mar";
        case 3:
            return "Abr";
        case 4:
            return "May";
        case 5:
            return "Jun";
        case 6:
            return "Jul";
        case 7:
            return "Ago";
        case 8:
            return "Sep";
        case 9:
            return "Oct";
        case 10:
            return "Nov";
        case 11:
            return "Dic";


    }
}