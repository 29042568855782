/**************************************************
 * Nombre:       ApartadoNoticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import Tarjeta_Noticias from "../../Tarjetas/Tarjeta_Noticias";
import {theme} from "../../../Tema";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import logo from '../../../Recursos/logooscuro.png'


const ApartadoNoticias = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [noticia, setNoticia] = useState({})

    useEffect(() => {
        obtenerDoc('noticiaHome', 'noticia').then((dox) => {
            if (dox.res) {
                setNoticia(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>


                    <Grid
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={500}
                        data-aos-duration="1000"
                        item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>

                        <Typography
                            sx={{
                                fontFamily: 'Playfair Display SC',
                                fontSize: masSM ? 52 : 36,
                                fontWeight: 800,
                                lineHeight: 1.1
                            }}>Noticias</Typography>

                    </Grid>


                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 14 : 10}}>
                    {noticia &&
                        <Tarjeta_Noticias valores={noticia} difiere={true}/>
                    }
                </Grid>

            </Grid>

        </Grid>
    )
}

export default ApartadoNoticias;

const notic = {
    fecha: '27 de Septiembre',
    titulo: 'Quichinche celebró sus fiestas patrimoniales',
    contenido: 'Del 6 al 19 de marzo la parroquia San José de Quichinche del cantón Otavalo, celebró sus fiestas en honor a su santo patrono. El programa de fiestas contempló varias actividades de tipo religioso, cultural y deportivo como: novenas, pregón de fiestas, Cross Contry a caballo, partidos de fútbol, ecuavoley, bandas del pueblo, peregrinaciones religiosas, festivales pirotécnicos, quema de castillos, carrera de coches de madera, shows artístico-bailables y festivales folklóricos en las que participarán todos los parroquianos.',
}