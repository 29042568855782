/**************************************************
 * Nombre:       CulturaFestejos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import estandarte from '../../../Recursos/estandarte.png'
import {theme} from "../../../Tema";


const CulturaFestejos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 6, pl: masSM ? 8 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{px: sCell ? 0 : 3}}
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={500}
                            data-aos-duration="1000"
                            container
                            justifyContent={'center'}
                            item lg={12} sm={12} xs={12}>
                            <Typography
                                sx={{
                                    fontFamily: 'Playfair Display SC',
                                    color: PRIMARIO,
                                    fontWeight: 800,
                                    fontSize: masSM ? 36 : 30
                                }}>
                                Cultura y Festejos<br/>
                                <span><Grid sx={{
                                    borderTop: 6,
                                    width: 80,
                                    borderColor: ACENTO,
                                    borderRadius: 8,
                                    marginTop: 0.5
                                }}/></span>
                            </Typography>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={1000}
                            data-aos-duration="1000"
                            item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Typography sx={{fontSize: sCell ? 18 : 22, textAlign: 'justify'}}>
                                Se invita a celebrar sus diferentes fiestas, festejos patronales y culturales que se
                                desarrollan en las distintas fechas del año. Somos una parroquia llena de tradición,
                                cultura, calidez y amabilidad para con nuestros visitantes y locales. No te pierdas
                                ninguno de estos festejos.
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 0}}>
                    <img

                        data-aos="fade-left"
                        data-aos-delay={sCell ? 500 : 1500}
                        data-aos-duration="1000"
                        src={estandarte} width={sCell ? '80%' : '60%'} height={'auto'}/>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default CulturaFestejos;