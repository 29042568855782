/**************************************************
 * Nombre:       Rendicion_Cuentas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, InputAdornment, MenuItem, TextField, Typography, useMediaQuery} from "@mui/material";
import {GRIS, ITEMSLOTAIP, MESES, PRIMARIO, YEARS} from "../../Constantes";
import {Calendar, Calendar1} from "iconsax-react";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Items from "../Tarjetas/Tarjeta_Items";
import Tarjetas_Lotaip from "../Tarjetas/Tarjetas_Lotaip";
import {theme} from "../../Tema";
import {LoadingButton} from "@mui/lab";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import LOTAIP from "../../Dashboard/Secciones/LOTAIP";
import {irArriba} from "../../Utilidades/irArriba";
import Tarjetas_Presupuesto from "../Tarjetas/Tarjetas_Presupuesto";
import {irUrl} from "../../Utilidades/irUrl";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";


const Compras_Publicas = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const [mes, setMes] = useState('')
    const [year, setYear] = useState('')
    const [lotaip, setLotaip] = useState([])
    const [cargando, setCargando] = useState(false)
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningProg({col: 'presupuestos', wheres: wheres})

    const consultar = () => {

        if (mes === '' || year === '') {
            alert('Mes y Año son necesarios para realizar la consulta')
            return
        }

        setCargando(true)
        let col = collection(fire, 'lotaip')
        let q = query(col, where('mes', "==", mes), where('year', '==', year))

        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                if (dox.data.length > 0) {
                    // alimentar lotaip
                    alimentarLotaip(dox.data[0])
                    // setLotaip(dox.data[0])
                }
            }
            setCargando(false)
        })
    }


    const alimentarLotaip = (data) => {

        let lotaip = ITEMSLOTAIP;
        for (let i = 0; i < lotaip.length; i++) {
            lotaip[i].archivo = data[lotaip[i].apartado]
            for (let j = 0; j < lotaip[i].items.length; j++) {
                lotaip[i].items[j].archivo = data[lotaip[i].items[j].letra]
            }

        }

        setLotaip(lotaip)
    }


    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{px: 4}}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <Typography
                                sx={{
                                    fontFamily: 'Playfair Display SC',
                                    fontSize: sCell ? 30 : 36,
                                    fontWeight: 700,
                                    color: PRIMARIO
                                }}
                            >
                                Proceso de contratacion publica
                            </Typography>
                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{marginTop: 6, justifyContent: 'center'}}>
                            <Typography sx={{fontSize: sCell ? 18 : 20, textAlign: 'justify'}}>
                                Para buscar coloca el nombre de la parroquia contratante y click en el boton BUSCAR
                                ENTIDAD, hacer click en el nombre de la parroquia. Ahora que esta colocado el nombre en
                                Entidad Contratante, nos vamos al final de la hoja y le hacemos click en Buscar
                            </Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                            {sCell ?


                                <Button
                                    onClick={() => irUrl('https://www.compraspublicas.gob.ec/ProcesoContratacion/compras/PC/buscarProceso.cpe')}
                                    variant={'contained'}>Ir a pagina de contratacion publica</Button>

                                :

                                <iframe frameBorder="1" height="800" scrolling="auto"
                                        src="https://www.compraspublicas.gob.ec/ProcesoContratacion/compras/PC/buscarProceso.cpe"
                                        width="730"></iframe>


                            }

                        </Grid>

                    </Grid>
                </Grid>





            </Grid>


        </Grid>
    )
}

export default Compras_Publicas;


