/**************************************************
 * Nombre:       FLogin
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import {Google, Lock} from "iconsax-react";
import logo from '../../Recursos/logolargoverde.png'
import {theme} from "../../Tema";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";


const Formulario_Login = ({...props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"));


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                sx={{p: sCell ? 1 : 2}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", m: 2}}>
                    <img src={logo} alt={"logo"} width={200} height={'auto'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Google} label={"Correo"} dato={"correo"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Lock} label={"Contraseña"} dato={"pass"} type={"password"}/>
                </Grid>


            </Grid>

        </FormBase>
    )
}

export default Formulario_Login;