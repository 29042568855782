import {Facebook, Instagram, Whatsapp, Map} from "iconsax-react";

export const PRIMARIO = '#009459'
export const ACENTO = '#276FA3'
export const GRIS = '#595959'
//0.236980, -78.288745

export const LINKMAP = "https://www.google.com/maps/dir/?api=1&destination=0.236980,-78.288745,190&dir_action=navigate"

export const LINKWASS = "https://api.whatsapp.com/send?phone=593990401078&text=Hola%20necesito%20mas%20informacion"

export const LINKPDOT = ''

export const SECCIONES = ['Home', 'Contactos', 'Hoteles', 'Lotaip', 'Restaurantes', 'Presupuesto', 'Lugares', 'Compras Publicas', 'Noticias', 'Rendicion de Cuentas']

export const SECCIONESORDEN = ['Home', 'Hoteles', 'Restaurantes', 'Lugares', 'Noticias', 'Contactos', 'Lotaip', 'Presupuesto', 'Compras Publicas', 'Rendicion de Cuentas']

export const DATOS = ['Telefono: 062668103', 'secretaria@quichinche.gob.ec', 'presidencia@quichinche.gob.ec', 'Otavalo - Imbabura - Ecuador']

export const CATEGORIAS = ['Hoteles', 'Restaurantes', 'Lugares']

export const MESES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const YEARS = ['2022', '2023', '2024', '2025', '2026']

export const REDES = [
    {
        nombre: 'facebook',
        icono: Facebook,
        url: 'https://www.facebook.com/profile.php?id=61550747890139'
    },
    {
        nombre: 'instagram',
        icono: Instagram,
        url: 'https://www.instagram.com/quichinche.2023/'
    },
    {
        nombre: 'wass',
        icono: Whatsapp,
        url: LINKWASS
    },
    {
        nombre: 'map',
        icono: Map,
        url: LINKMAP
    }
]

export const ITEMSLOTAIP = [
    {
        apartado: 'A',
        descripcion: 'Estructura orgánica funcional, base legal que la rige, regulaciones y procedimientos internos aplicables a la entidad; las metas y objetivos de las\n' +
            'unidades administrativas de conformidad con sus programas operativos',
        items: [
            {
                letra: 'A1',
                texto: 'Estructura orgánica funcional'
            },
            {
                letra: 'A2',
                texto: 'Base legal que rige'
            },
            {
                letra: 'A3',
                texto: 'Regulaciones y procedimientos internos'
            },
            {
                letra: 'A4',
                texto: 'Metas y objetivos de las unidades administrativas'
            }
        ]
    },
    {
        apartado: 'B',
        descripcion: 'El directorio completo de la institución, así como su distributivo de personal',
        items: [
            {
                letra: 'B1',
                texto: 'Directorio completo de la institución'
            },
            {
                letra: 'B2',
                texto: 'Distributivo de personal'
            }
        ]
    },
    {
        apartado: 'C',
        descripcion: 'La remuneración mensual por puesto y todo ingreso adicional, incluso el sistema de compensación, según lo establezcan las disposiciones correspondientes',
        items: []
    },
    {
        apartado: 'D',
        descripcion: ' Los servicios que ofrece y las formas de acceder a ellos, horarios de atención y demás indicaciones necesarias, para que la ciudadanía pueda ejercer sus derechos y cumplir sus obligaciones',
        items: []
    },
    {
        apartado: 'E',
        descripcion: 'Texto íntegro de todos los contratos colectivos vigentes en la institución, así como sus anexos y reformas',
        items: []
    },
    {
        apartado: 'F',
        descripcion: 'Se publicarán los formularios o formatos de solicitudes que se requieran para los trámites inherentes a su campo de acción',
        items: [
            {
                letra: 'F1',
                texto: 'Formularios o formatos de solicitudes'
            },
            {
                letra: 'F2',
                texto: 'Formato para solicitudes de acceso a la información pública'
            },

        ]
    },
    {
        apartado: 'G',
        descripcion: ' Información total sobre el presupuesto anual que administra la institución, especificando ingresos, gastos, financiamiento y resultados operativos de conformidad con los clasificadores presupuestales, así como liquidación del presupuesto, especificando destinatarios de la entrega de recursos públicos',
        items: []
    },
    {
        apartado: 'H',
        descripcion: 'Los resultados de las auditorías internas y gubernamentales al ejercicio presupuestal',
        items: []
    },
    {
        apartado: 'I',
        descripcion: 'Información completa y detallada sobre los procesos precontractuales, contractuales, de adjudicación y liquidación, de las contrataciones de obras, adquisición de bienes, prestación de servicios, arrendamientos mercantiles, etc., celebrados por la institución con personas naturales o jurídicas, incluidos concesiones, permisos o autorizaciones',
        items: []
    },
    {
        apartado: 'J',
        descripcion: 'Un listado de las empresas y personas que han incumplido contratos con dicha institución',
        items: []
    },
    {
        apartado: 'K',
        descripcion: 'Planes y programas de la institución en ejecución',
        items: []
    },
    {
        apartado: 'L',
        descripcion: ' El detalle de los contratos de crédito externos o internos; se señalará la fuente de los fondos con los que se pagarán esos créditos. Cuando se trate de préstamos o contratos de financiamiento, se hará constar, como lo prevé la Ley Orgánica de Administración Financiera y Control, Ley Orgánica de la Contraloría General del Estado y la Ley Orgánica de Responsabilidad y Rendicion_Cuentas Fiscal, las operaciones y contratos de crédito, los montos, plazo, costos financieros o tipos de interés',
        items: []
    },
    {
        apartado: 'M',
        descripcion: 'Mecanismos de rendición de cuentas a la ciudadanía, tales como metas e informes de gestión e indicadores de desempeño',
        items: []
    },
    {
        apartado: 'N',
        descripcion: 'Los viáticos, informes de trabajo y justificativos de movilización nacional o internacional de las autoridades, dignatarios y funcionarios públicos',
        items: []
    },
    {
        apartado: 'O',
        descripcion: 'El nombre, dirección de la oficina, apartado postal y dirección electrónica del responsable de atender la información pública de que trata esta Ley',
        items: []
    },
    {
        apartado: 'P',
        descripcion: 'La Función Judicial y el Tribunal Constitucional, adicionalmente, publicarán el texto íntegro de las sentencias ejecutoriadas, producidas en todas sus jurisdicciones',
        items: []
    },
    {
        apartado: 'Q',
        descripcion: 'Los organismos de control del Estado, adicionalmente, publicarán el texto íntegro de las resoluciones ejecutoriadas, así como sus informes, producidos en todas sus jurisdicciones',
        items: []
    },
    {
        apartado: 'S',
        descripcion: 'Los organismos seccionales, informarán oportunamente a la ciudadanía de las resoluciones que adoptaren, mediante la publicación de las actas de las respectivas sesiones de estos cuerpos colegiados, así como sus planes de desarrollo local',
        items: []
    }


]