export const iniciarChatWhatsApp = ({num, nombre=" ", tema = " "}) => {


    const text = `https://api.whatsapp.com/send?phone=593${num}&text=Hola%20${procesarTexto(nombre)}%20necesito%20mas%20informacion%3A%0A${procesarTexto(tema)}`

    window.open(text)

}

const procesarTexto = (text) => {

    if (text === "") {
        return text
    } else {
        let noEspacios = text.replaceAll(" ", "%20")
        return noEspacios
    }


}