/**************************************************
 * Nombre:       DetalleItem
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import {Whatsapp} from "iconsax-react";
import {iniciarChatWhatsApp} from "../../../Utilidades/iniciarChatWhatsApp";
import {theme} from "../../../Tema";


const DetalleItem = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >


            <Grid item lg={12} sm={12} xs={12}>
                <img src={item && item.img} width={'100%'} height={'auto'}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{color: PRIMARIO, fontWeight: 600}}>{item && item.tipo}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                        <Typography
                            sx={{
                                fontFamily: 'Playfair Display SC',
                                fontWeight: 800,
                                fontSize: 32,
                                textAlign: 'center'
                            }}>
                            {item && item.nombre}
                        </Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{textAlign: 'justify'}}>
                            {item && item.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        {item && item.wass &&
                        <Button
                            onClick={() => iniciarChatWhatsApp({num: item.wass, nombre: item.titulo})}
                            variant={'outlined'} startIcon={<Whatsapp variant={"Bold"}/>}
                            sx={{px: 4, borderRadius: 6}}>Chat
                            WhatsApp</Button>
                        }
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )
}

export default DetalleItem;