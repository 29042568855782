/**************************************************
 * Nombre:       Comentarios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {PRIMARIO} from "../../../Constantes";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Testimonio from "../../Tarjetas/Tarjeta_Testimonio";
import {theme} from "../../../Tema";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Comentario from "../../Formularios/Formulario_Comentario";
import {verificarCamposObligatorios} from "../../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";
import {where} from "firebase/firestore";
import useRecuperarColleccionListeningProg from "../../../Servicios/BD/useRecuperarColleccionListeningProg";


const Comentarios = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Comentario,
        nombre: 'comentario'
    })
    const [wheres, setWheres] = useState([where('idNoticia', '==', 'none')])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'comentarios',
        direccion: 'desc',
        wheres: wheres
    })
    const [cargando, setCargando] = useState(false)


    const guardar = () => {

        if (!verificarCamposObligatorios(entidad, ['nombre', 'correo', 'comentario'])) {
            return;
        }

        setCargando(true)
        entidad.fecha = new Date().getTime()
        entidad.idNoticia = item.id
        entidad.aprobado = false
        if (item.idNoticia !== undefined) {
            entidad.idNoticia = item.idNoticia
        }
        guardarDoc('comentarios', entidad).then((dox) => {
            if (dox.res) {
                alert('Comentario enviado con exito\nPronto se visualizara el comentario')
                limpiarEntidad()
            } else {
                alert('Algo inesperado paso, por favor intentalo nuevamente\n\nERR:COM002')
            }
            setCargando(false)
        })

    }


    useEffect(() => {

        let idNoticia = item.id

        if (item.idNoticia !== undefined) {
            idNoticia = item.idNoticia
        }

        if (idNoticia) {
            setWheres([where('idNoticia', '==', idNoticia), where('aprobado', '==', true)])
        }


    }, [item])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item lg={11} sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontSize: sCell ? 24 : 30,
                            fontWeight: 700
                        }}

                    >Comentarios</Typography>
                </Grid>

                <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 6}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Ho_ManejoTargetas Card={Tarjeta_Testimonio} data={data} lg={6} spacing={8} sm={6}/>


                    </Grid>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{p: 2, marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <Typography
                                sx={{
                                    fontFamily: 'Playfair Display SC',
                                    color: PRIMARIO,
                                    fontSize: 30,
                                    fontWeight: 700
                                }}

                            >Deja tu comentario</Typography>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Formulario {...props} />
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                            <LoadingButton
                                loading={cargando}
                                onClick={() => guardar()}
                                variant={'outlined'} sx={{px: 4, borderRadius: 8}}> Subir Comentario</LoadingButton>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container lg={6} sm={12} xs={12}
                      sx={{justifyContent: "center", borderTop: 2, height: 1, borderColor: PRIMARIO, marginTop: 10}}>

                </Grid>


            </Grid>

        </Grid>
    )
}

export default Comentarios;


const comentarios = [
    {
        nombre: 'Edgar Caballero',
        comentario: 'Un lugar bonito con el parque remodelado y donde puedes disfrutar de unos ricos helados y también puedes visitar la iglesia del lugar...'
    },
    {
        nombre: 'Gabriel Estrada',
        comentario: 'Un bonito lugar para visitar si se va a Otavalo ya que esta cerca de la ciudad y tomando un bus se puede llegar fácilmente.'
    },
    {
        nombre: 'Diego Gordillo',
        comentario: 'Un lugar hermoso, pueblo pequeño pero con personas amables.'
    },
]