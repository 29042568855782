/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import Portada from "./Componentes/Portada";
import ApartadoNoticias from "./Componentes/ApartadoNoticias";
import JuntaParroquial from "./Componentes/JuntaParroquial";
import CulturaFestejos from "./Componentes/CulturaFestejos";
import FechasImportantes from "./Componentes/FechasImportantes";
import Frase from "./Componentes/Frase";
import {irArriba} from "../../Utilidades/irArriba";
import {theme} from "../../Tema";
import MisionVision from "./Componentes/MisionVision";


const Home = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))


    useEffect(() => {
       // irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12} >
                <Portada/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? -18 : 8, zIndex:2}}>
                <ApartadoNoticias/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 16}}>
                <JuntaParroquial/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 16}}>
                <CulturaFestejos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", backgroundColor: '#F5F5F5', marginTop: 16}}>
                <FechasImportantes/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 12, marginBottom: 4}}>
                <MisionVision/>
            </Grid>

        </Grid>
    )
}

export default Home;