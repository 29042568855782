/**************************************************
 * Nombre:       Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {funRecuperarContrasena} from "../../Servicios/Auth/funRecuperarContrasena";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Login from "../Formularios/Formulario_Login";
import {LoadingButton} from "@mui/lab";


const Login = ({id, cerrar,}) => {
    const {Formulario, props, entidad} = useFormulario({Form: Formulario_Login, nombre: "login",})

    const [cargando, setCargando] = useState(false)


    const ingresar = () => {

        setCargando(true)
        funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
            if (!dox.res) {
                alert(dox.data)
            } else {
                console.log("Ingreso a la plataforma")
            }
            setCargando(false)
        })

    }


    const recuperarContrasena = () => {
        setCargando(true)
        funRecuperarContrasena(entidad.correo).then((dox) => {
            if (dox.res) {
                alert("Se ha enviado las instrucciones al correo " + entidad.correo + ".")
            } else {
                alert(dox.data)
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >


            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <Button
                    sx={{color: "#000", fontSize: 16}}
                    variant={"text"}
                    onClick={() => recuperarContrasena()}
                >Olvidé mi contraseña</Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2, marginBottom: 2}}>
                <LoadingButton
                    color={'secondary'}
                    sx={{borderRadius: 2, color: '#fff'}}
                    onClick={() => ingresar()}
                    variant={"contained"}
                    loading={cargando}>Ingresar</LoadingButton>
            </Grid>


        </Grid>
    )
}

export default Login;