export const escogerProximoEventoIndex = (fechas) => {

    let hoy = new Date().getTime() - 86400000
    let num = fechas ? fechas.length : 0
    let index = fechas.findIndex((e) => e.fecha > hoy)

    if (index === -1) {
        index = num - 1
    }

    return index

}