/**************************************************
 * Nombre:       Formulario_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {FormBase, ImagenDimencion, InputCheck} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Flag, Message, Message2, Sms, Text, User} from "iconsax-react";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const Formulario_Pqrs = ({...props}) => {


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIconFecha dato={'fecha'} label={"Fecha de la Pqrs"} editable={false}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Flag} dato={'tipo'} label={"Tipo"} editable={false}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} dato={'nombre'} label={"Nombre"} editable={false}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Sms} dato={'correo'} label={"Correo Electronico"} editable={false}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message2} dato={'mensaje'} label={"Mensaje"} lineas={8}
                                        editable={false}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message2} dato={'notas'} label={"Notas"} lineas={8}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Pqrs;