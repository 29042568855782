/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import BarraPc from "./Componentes/BarraPc";
import {theme} from "../../Tema";
import BarraCell from "./Componentes/BarraCell";


const Barra = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{zIndex: 6}}

        >

            {masSM ? <BarraPc/> : <BarraCell/>}

        </Grid>
    )
}

export default Barra;

