/**************************************************
 * Nombre:       JuntaParroquial
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, TypographyVariantsOptions, useMediaQuery} from "@mui/material";
import {ACENTO, PRIMARIO} from "../../../Constantes";
import personauno from '../../../Recursos/personauno.png'
import personados from '../../../Recursos/personados.png'
import personatres from '../../../Recursos/personatres.png'
import personacuatro from '../../../Recursos/personacuatro.png'
import personacinco from '../../../Recursos/personacinco.png'
import {theme} from "../../../Tema";
import logo from '../../../Recursos/logooscuro.png'


const JuntaParroquial = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={500}
                    data-aos-duration="1000"
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography

                        sx={{
                            fontFamily: 'Playfair Display SC',
                            color: PRIMARIO,
                            fontWeight: 800,
                            fontSize: masSM ? 36 : 30
                        }}>
                        Junta parroquial<br/>
                        <span><Grid sx={{
                            borderTop: 6,
                            width: 80,
                            borderColor: ACENTO,
                            borderRadius: 8,
                            marginTop: 0.5
                        }}/></span>
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={11} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <Grid
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={500}
                        data-aos-duration="1000"
                        item lg={7} sm={12} xs={12}>
                        <Typography sx={{textAlign: 'justify', fontSize: sCell ? 18 : 20}}>Como junta parroquial de
                            Quichinche estamos
                            comprometidos en poder brindar un mejor servicio para todas las comunidades y los barrios que
                            conforman nuestra parroquia juntos construiremos una parroquia digna con mayores
                            oportunidades para todas y todos</Typography>
                    </Grid>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 14}}>
                    <img src={logo} width={'90%'} height={'auto'} style={{opacity: 0.1}} />
                </Grid>


                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: masSM ? -50 : -20}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={1000}
                            data-aos-duration="1000"
                            item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={personauno} width={sCell ? '70px' : '130px'} height={'auto'}
                                 title={'Personal de la junta'}/>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={1500}
                            data-aos-duration="1000"
                            item container lg={2} sm={2} xs={2} sx={{justifyContent: "center", marginTop: 10}}>
                            <img src={personados} width={sCell ? '70px' : '130px'} height={'auto'}
                                 title={'Personal de la junta'}/>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={2000}
                            data-aos-duration="1000"
                            item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={personatres} width={sCell ? '70px' : '130px'} height={'auto'}
                                 title={'Personal de la junta'}/>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={2500}
                            data-aos-duration="1000"
                            item container lg={2} sm={2} xs={2} sx={{justifyContent: "center", marginTop: 10}}>
                            <img src={personacuatro} width={sCell ? '70px' : '130px'} height={'auto'}
                                 title={'Personal de la junta'}/>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={3000}
                            data-aos-duration="1000"
                            item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <img src={personacinco} width={sCell ? '70px' : '130px'} height={'auto'}
                                 title={'Personal de la junta'}/>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default JuntaParroquial;