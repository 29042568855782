/**************************************************
 * Nombre:       Formulario_Noticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {FormBase, ImagenDimencion, InputArchivoPDF} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Message, Message2, Text} from "iconsax-react";
import {verificarCamposObligatorios} from "../../Servicios/BD/verificarCamposObligatorios";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";
import {subirArchivoPDF} from "../../Servicios/Storage/subirArchivoPDF";


const Formulario_Presupuestos = ({...props}) => {


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.ImagenDimencion dato={'img'} y={2} x={3} carpeta={'presupuesto'}
                                              funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIconFecha dato={'fecha'} label={"Fecha de Publicacion"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Text} dato={'titulo'} label={"Titulo Presupuesto"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} dato={'parrafo'} label={"Descripcion"} lineas={10}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputArchivoPDF dato={'archivo'} label={'PDF del presupuesto'} carpeta={'Presupuestos'}
                                              funcion={subirArchivoPDF}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Presupuestos;