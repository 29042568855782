export const verificarCamposObligatorios = (entidad, campos = []) => {

    for (let i = 0; i < campos.length; i++) {
        if (entidad[campos[i]] === undefined) {
            alert('El campo ' + campos[i])
            return false
        }
    }

    return true
}