/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CardBase, FechaComponente} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {
    Book,
    Box,
    Buildings,
    Flag,
    Lock,
    Lovely,
    Message,
    Message2,
    Mobile,
    Money,
    Moneys,
    Reserve,
    Sms, Text, User
} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {theme} from "../../Tema";
import {NUMWASS, PRIMARIO} from "../../Constantes";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {irUrl} from "../../Utilidades/irUrl";


const Tarjeta_Comentario = ({valores, click}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <CardBase valores={valores} sombra={0} sx={{width: '100%'}}>

            <ButtonBase
                onClick={() => click(valores)}
                sx={{borderRadius: 2, width: '100%'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 1}}
                    spacing={masSM ? 0 : 1}
                >


                    <Grid item lg={3} sm={6} xs={12}>
                        <CardBase.FechaComponente dato1={"fecha"}>
                            <CardBase.IconTexto Icono={User} dato1={"nombre"}/>
                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                        <CardBase.IconTexto Icono={Sms} dato1={"correo"} lineas={2}/>
                    </Grid>

                    <Grid item lg={3} sm={12} xs={12}>
                        <CardBase.IconTextoParrafo dato1={"comentario"} lineas={2}/>
                    </Grid>

                    <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                        {valores.aprobado &&
                        <Typography>Aprobado</Typography>
                        }
                    </Grid>

                </Grid>
            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Comentario;