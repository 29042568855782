/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, ButtonBase, Fade, Grid, MenuItem, Typography, Menu} from "@mui/material";
import logo from "../../../Recursos/logolargoverde.png";
import {ACENTO, REDES} from "../../../Constantes";
import {useNavigate} from 'react-router-dom'
import {irUrl} from "../../../Utilidades/irUrl";
import BotonItem from "./BotonItem";


const BarraPc = () => {
    const navigate = useNavigate()

    return (
        <Grid
            component={"div"}
            data-aos="fade-down"
            data-aos-delay={0}
            data-aos-duration="1000"
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: ACENTO, zIndex: 1}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', py: 1.6, px: 2}}
            >


                <Grid item container lg={2} sm={2} xs={12}>
                    <ButtonBase onClick={() => navigate('/Home')}>
                        <img src={logo} width={190} height={'auto'} title={'Logo'} alt={'Logo'}/>
                    </ButtonBase>

                </Grid>

                <Grid item lg={2} sm={1} xs={12}>


                </Grid>

                <Grid item container lg={8} sm={9} xs={12} sx={{justifyContent: 'flex-end', pr: 2}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                            <ButtonBase
                                onClick={() => navigate('/')}
                                sx={{
                                    transition: 'all .2s ease-in-out',
                                    borderRadius: 0,
                                    px: 1,
                                    borderBottom: 2,
                                    borderColor: '#00000000',
                                    '&:hover': {borderColor: '#fff'}
                                }}>
                                <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 500,}}>Home</Typography>
                            </ButtonBase>
                        </Grid>


                        <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <BotonItem titulo={'Turismo y Gastronomia'} items={['Hoteles', 'Restaurantes', 'Lugares']}/>
                        </Grid>


                        <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                            <ButtonBase
                                onClick={() => navigate('/Noticias')}
                                sx={{
                                    transition: 'all .2s ease-in-out',
                                    borderRadius: 0,
                                    px: 1,
                                    borderBottom: 2,
                                    borderColor: '#00000000',
                                    '&:hover': {borderColor: '#fff'}
                                }}>
                                <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 500,}}>Noticias</Typography>
                            </ButtonBase>
                        </Grid>

                        <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                            <ButtonBase
                                onClick={() => navigate('/Contactos')}
                                sx={{
                                    transition: 'all .2s ease-in-out',
                                    borderRadius: 0,
                                    px: 1,
                                    borderBottom: 2,
                                    borderColor: '#00000000',
                                    '&:hover': {borderColor: '#fff'}
                                }}>
                                <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 500,}}>Contactos</Typography>
                            </ButtonBase>
                        </Grid>

                        <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <BotonItem titulo={'Transparencia'}
                                       items={['Lotaip', 'Presupuesto', 'Compras Publicas', 'Rendicion de Cuentas']}/>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default BarraPc;

export const SECCIONES = ['Turismo y Gastronomia', 'Noticias', 'Contactos', 'Transparencia',]
